import React, { Component } from 'react';
import { connect } from 'react-redux';

import authaxios from '../../auth/authaxios';
import * as actions from '../../store/actions';

import styles from './account.module.css';
import Spinner from '../../components/UI/loading/Spinner/Spinner';
import SubmitBtn from '../../components/UI/buttons/SubmitBtn.js';
import LabeledInput from '../../components/UI/inputs/LabeledInput.js';
import ErrorMsg from '../../components/UI/messages/Fail';

// Class for displaying the Change Password form
// Class Author: @Joe Hall
class Security extends Component {

    state = {
        oldPass: "",
        newPass: "",
        newPasstwo: "",
        loading: false,
        oldPassError: false,
        newPassError: false,
        newPassTwoError: false,
        errorMessage: ""
    }

    // Handler for when the old password input changes, updates state.
    onChangeOldPasswordHandler = (event) => {
        this.setState({oldPass: event.target.value, oldPassError: event.target.value === ""});
    }

    // Handler for when the new password input changes. Validates the password, and then updates state
    onChangeNewPasswordHandler = (event) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,60}$/;
        let passed = regex.test(event.target.value);
        this.setState({newPass: event.target.value, newPassError: !passed});
    }

    // Handler for when the confirm password input changes, validates it matches the first password and updates state
    onChangeNewPasswordTwoHandler = (event) => {
        let passed = this.state.newPass === event.target.value;
        this.setState({newPassTwo: event.target.value, newPassTwoError: !passed});
    }

    // Hanlder for when the user clicks update password button. Validates all form fields are correct
    // and then sends the request to the API to change the password. Sets loadding spinner while 
    // performing network request
    changePasswordHandler = (event) => {
        event.preventDefault();

        if(this.state.oldPassError || this.state.newPassError || this.state.newPassTwoError) return;
        if(this.state.oldPass === "" || this.state.newPass === "" || this.state.newPassTwo === "") {
            this.setState({errorMessage: "Please fill out all fields"});
            return;
        }
        this.setState({errorMessage: "", loading: true});

        authaxios.post("/user/updatepassword.php",
        {
            old_password: this.state.oldPass,
            new_password: this.state.newPass
        })
        .then(response => {
            this.addBannerHandler("success", "Successfully updated password!", true);
        })
        .catch(error => {
            this.addBannerHandler("error", "Error: " + error.response.data.Response, true);
        })
        .finally(() => {
            this.setState({loading: false})
        });
    }

    // function for adding either a success banner or failure banner
    addBannerHandler = (type, text, autoClose=false, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        };
        
        this.props.addBanner(banner);
    }

    // The render method that displays the change password form.
    render() {
        return(
            <div>
                <h1>Change my Password</h1>
                <div className={styles.mainContent}>
                    <form onSubmit={this.changePasswordHandler}>
                        <LabeledInput label='Old Password' type="password" invalidBool={this.state.oldPassError}
                            checkHandler={this.onChangeOldPasswordHandler} errorMsg='This can not be empty'/>
                        <LabeledInput label='New Password' type="password" name='password' invalidBool={this.state.newPassError}
                            checkHandler={this.onChangeNewPasswordHandler} errorMsg='Password must be at least 8 characters and contain at least 1 capital letter and 1 number'/>
                        <LabeledInput label='Re-type New Password' type="password" name='password2' invalidBool={this.state.newPassTwoError}
                            checkHandler={this.onChangeNewPasswordTwoHandler} errorMsg='New Passwords do not match'/>

                        <br/>
                        {this.state.loading ? <div>&nbsp;<Spinner show={true} />&nbsp;</div> : <SubmitBtn text="Update Password" />}
                        <ErrorMsg message={this.state.errorMessage} show={this.state.errorMessage !== ""} />
                    </form>
                </div>
            </div>
        );
    }
}

// Tell redux connect that we will call the redux action addBanner so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};
export default connect(null, mapDispatchToProps)(Security);