import React from 'react';
import styles from './buttons.module.css';
import { FaTrash } from 'react-icons/fa';

/*
-- Trash Icon Button --
The trash icon button
Props:
- className and/or id: applied to button
- disabled: bool representing whether the button is disabled or not
- onClick: function that happens on button click
*/

const TrashBtn =(props) => {
    return (
        <FaTrash className={`${styles.iconBtn} ${props.disabled ? styles.disabledBtn : styles.enabledBtn} ${props.className}`}
                id={props.id} onClick={props.disabled ? null : props.onClick} />
    );
}

export default TrashBtn;