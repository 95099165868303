import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IS_DEVELOPMENT } from '../Globals';

import * as actions from '../store/actions';
import FullPageLoad from '../components/UI/loading/fullpageload/fullpageload';

import authaxios from './authaxios';

// Class Author: @Joe Hall
class Auth extends Component {

    state = {
        loading: false,
        authFinished: false,
        loggedIn: false
    }

    // When the Auth component mounts, Display a fullscreen loading page while it determines if the user is logged in or not,
    // If there is a JWT token stored in cookies, then reach out to /user.php to get the current user, if that succeeds than the user
    // is logged in in which case call Redux action Login() and pass the user.
    // If not, call redux action Logout()
    // AUTHOR: @Joe Hall
    componentDidMount() {
        // Set the full screen loader to true
        this.setState({loading: true});
        //Start the interval timer of calling refreshToken every 15 minutes.
        setInterval(this.refreshToken, 1000 * 60 * 8);
        authaxios.get('/user.php')
        .then(response => {
            this.props.login(response.data);
            setTimeout(this.refreshToken, 1000 * 10);
        })
        .catch(() => {
            if (IS_DEVELOPMENT) localStorage.removeItem("token");
            this.props.logout();
        })
        .finally(() => {
            this.setState({loading: false, authFinished: true});
        });
    };


    // This function will look to see if a JWT token is stored in cookies, if so, it will reach out
    // to /user/tokenexchange.php to fetch a new token. If that succeeds (i.e the previous token was still valid)
    // it will update the token in cookies for the next authaxios call
    // AUTHOR: @Joe Hall
    refreshToken = () => {
        //const token = getCookie("token");
            authaxios.get('/user/tokenexchange.php')
            .then(response => {
                if (IS_DEVELOPMENT) localStorage.setItem("token", response.data.token);
            })
            .catch(error => {
                this.props.logout();
            });
        
    };

    // This will manage redirecting users to log in whenever the redux state.loggedIn is changed to false
    // AUTHOR: @Joe Hall
    render() {
        if(!this.props.loggedIn && this.state.authFinished && window.location.pathname !== '/reset/password' && window.location.pathname !== '/user/verify-email' && window.location.pathname !== '/invite') {
            return <Redirect to='/login' />
        }
        return(
            <React.Fragment>
                <FullPageLoad show={this.state.loading} />
            </React.Fragment>
        )
    }
}

// Tell redux connect that we want to listen to changes in the state loggedIn, and we want it as props
const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn
    }
};

// Tell redux connect that we will be dispatching the actions USER_LOGIN and USER_LOGOUT, and we want those functions as props
const mapDispatchToProps = dispatch => {
    return {
        login: (user) => dispatch({type: actions.USER_LOGIN, user: user}),
        logout: () => dispatch({type: actions.USER_LOGOUT})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);