import React from 'react';
import styles from './messages.module.css';

const FailMsg =(props) => {
    if (props.show) {
        return (
            <span className={`${styles.message} ${props.className}`} id={styles.fail}>{props.children}</span>
        );
    } else {
        return <span>&nbsp;</span>;
    }
}

export default FailMsg;