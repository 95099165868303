import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../store/actions';

import styles from './document.module.css';
import Spinner from '../components/UI/loading/Spinner/Spinner';


class VerifyEmail extends Component {
    state = {
        loading: false,
        token: null,
        success: false,
        errorMessage: null
    }

    addBannerHandler = (type, text, autoClose=true, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        }
        this.props.addBanner(banner);
    }

    // When the component mounts, check to see if theres an ID parameter in the URL,
    // If so then set state resetingPassword to true, and state token to the URL parameter
    componentDidMount() {
        if(window.location.search.startsWith("?token=")) {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            this.setState({loading: true, token: token});

            axios.patch(`/user/verify_email.php?token=${token}`)
            .then(response => {
                this.setState({loading: false, errorMessage: null, success: true});

                // If the user is already logged in, automatically redirect to the dashboard and show a banner
                if (this.props.loggedIn) {
                    this.addBannerHandler("success", "Email has been verified!");
                    this.props.history.push("/");
                }
            })
            .catch(error => {
                this.setState({loading: false, errorMessage: error.response.data.Response, success: false});

                // If the user is already logged in, automatically redirect to the dashboard and show a banner
                if (this.props.loggedIn) {
                    this.addBannerHandler("error", "Email could not be verified!");
                    this.props.history.push("/");
                }
            });
        } else {
            this.props.history.push("/");
        }
    }
    
    render(){
        return(
            <div>
                {this.state.loading ? <h1 className={styles.h1}>Verifying your email</h1> : null }
                {this.state.success ? <h1 className={styles.h1}>Verified</h1> : null }
                {this.state.errorMessage ? <h1 className={styles.h1}>Verification Failed</h1> : null }
                <div className={styles.SpinnerCenter}><Spinner show={this.state.loading} /></div>
                {this.state.success ? <p className={styles.center}>Your email has been verified. Please log in below</p> : null }
                {this.state.errorMessage ? <p className={styles.center}>{this.state.errorMessage}</p> : null }
                {!this.state.loading ? <p className={styles.center}>{this.props.loggedIn ? <Link to="/" title="Got to the Dashboard">Go to Dashboard</Link> : <Link to="/login" title="Log into PathNOTES">Login</Link>}</p> : null }
            </div>
        )
    };
}

// Tell redux connect that we want the loggedIn state as props
const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn
    }
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);