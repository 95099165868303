import React, { Component} from 'react';
import { connect } from 'react-redux';

import styles from './Dashboard.module.css';
import CourseCard from './CourseCard';
import authaxios from '../../auth/authaxios';
import Spinner from '../../components/UI/loading/Spinner/Spinner';
import Popup from '../../components/UI/popups/popup';
import TextInput from '../../components/UI/inputs/TextInput';
import TextArea from '../../components/UI/inputs/TextArea';
import Button from '../../components/UI/buttons/Button';
import * as actions from '../../store/actions';


// Class for displaying the course dashboard
// Class Author: @Joe Hall
class Dashboard extends Component {

    // Set initial State
    state = {
        courses: {
            instructing: [],
            enrolled: []
        },
        loading: false,
        creatingCourse: false,
        newCourse: {
            code: "",
            name: "",
            description: "",
            active: 1,
            colour: "#ee4444"
        },
        newCourseErrors: {
            code: null,
            name: null,
            description: null,
            colour: null
        }
    }

    // When the component mounts, call the loadCourses function
    componentDidMount() {
        this.loadCourses();
    }

    // Function for gettting a list of all the courses the user is enrolled in from the API,
    // it will update state.courses with a new array of courses
    loadCourses = () => {
        // Get the user's courses from the api
        this.setState({loading: true})
        authaxios.get('/course.php')
        .then(response => {
            this.setState({courses: response.data});
        })
        .catch(error => {
            //console.log(error.response.data.Response);
        })
        .finally(() => {
            this.setState({loading: false});
        });
    }

    // Handler for toggling the create new course popup to be open or closed
    toggleCreateNewCourseHandler = () => {
        this.setState({creatingCourse: !this.state.creatingCourse});
    }

    // Handler for when an input field in the add new course popup changes,
    // will call checkCourseData and update the state.newCourse
    newCourseInputHandler = (event) => {
        this.checkNewCourseData(event.target.name, event.target.value);
        let course = {...this.state.newCourse};
        course[event.target.name] = event.target.value;
        this.setState({newCourse: course});
    }

    // Function for validating the data of a new course.
    // it will update state.newCourseErrors with the appropriate error message (or null)
    // and return true or false depending on whether the input is valid or not
    checkNewCourseData = (name, value) => {
        let error = null;
        switch(name) {
            case 'code':
                if(!/^([a-zA-Z]{2,5})([a-zA-Z\d]{2,3})$/.test(value)) error = "Course code must be in format A(3-5)+1(3-4)";
                break;
            case 'name':
                if(!/^([a-zA-Z\s]{4,45})$/.test(value)) error = "Name must be between 4 and 45 characters long";
                 break;
            case 'description':
                if(value.length < 5) error = "Description must contain at least 5 characters";
                break;
            case 'colour':
                if(!/^#[0-9a-fA-F]{6}$/.test(value)) error = "Congrats! You've found a colour bug";
                break;
            case 'active':
                error = null;
                break;
            default: 
                return false;
        }

        let course = {...this.state.newCourseErrors};
        course[name] = error;
        this.setState({newCourseErrors: course});
        if(error) return false; else return true;
    }

    // Handler for when the user clicks submit on create new course popup.
    // it will validate all the course data again, and only if it passes,
    // with it make the api request to add the course.
    // if it succeeds it will throw a success banner up
    // and then update the state.courses with the new course inserted
    createNewCourseHandler = (event) => {
        // Turn on the loading spinner
        this.setState({loading: true});

        let newCourse = {...this.state.newCourse};

        // Checks validity of all the inputted course information
        for (var key in newCourse) {
            if (newCourse.hasOwnProperty(key)) {
                if(!this.checkNewCourseData(key, newCourse[key])) {
                    this.setState({loading: false});
                    return false;
                }
            }
        }

        authaxios.post('/course.php', {
            code: this.state.newCourse.code,
            name: this.state.newCourse.name,
            description: this.state.newCourse.description,
            active: this.state.newCourse.active,
            colour: this.state.newCourse.colour
        })
        .then(response => {
            let courses = {...this.state.courses};
            let i_courses = [...courses.instructing];
            i_courses.push(response.data);
            courses.instructing = i_courses;
            this.setState({creatingCourse: false, courses: courses, loading: false});

            // Create and add a banner on success
            const banner = {
                type: "success",
                link: `/course/${response.data.course_id}`,
                linkText: "View Course",
                text: "Course has been created",
                autoClose: true
            }
            this.props.addBanner(banner);
            //this.loadCourses();
        })
        .catch(error => {
            this.setState({loading: false});
        })
    }

    // Function called when a course is clicked
    // Goes to a page for that course, supplying the course id via the URL
    courseClickHandler = (id) => {
        this.props.history.push(`/course/${id}/information`);
    }


    render() {
        return(
            <>
            <Popup show={this.state.creatingCourse} title="Create New Course" confirmText="Create" confirmHandler={this.createNewCourseHandler} cancel={this.toggleCreateNewCourseHandler} allowClickAway={false}>
                <div className={styles.NewCourseForm}>
                    <TextInput name="code" placeholder="Course Code eg. COMP123" value={this.state.newCourse.code} invalidBool={this.state.newCourseErrors.code} errorMsg={this.state.newCourseErrors.code} checkHandler={this.newCourseInputHandler} />
                    <TextInput name="name" placeholder="Course Name" value={this.state.newCourse.name} invalidBool={this.state.newCourseErrors.name} errorMsg={this.state.newCourseErrors.name} checkHandler={this.newCourseInputHandler} />
                    <TextArea name="description" value={this.state.newCourse.description} placeholder="Course Description" invalidBool={this.state.newCourseErrors.description} errorMsg={this.state.newCourseErrors.description} checkHandler={this.newCourseInputHandler} row="10" col="30" />
                    <p>Course Colour</p>
                    <TextInput name="colour" placeholder="Course Colour" type="color" value={this.state.newCourse.colour} invalidBool={this.state.newCourseErrors.colour} errorMsg={this.state.newCourseErrors.colour} checkHandler={this.newCourseInputHandler} />
                    <div><Spinner show={this.state.loading && this.state.creatingCourse} /></div>
                    <input type="submit" value="Create Course" style={{display: 'none'}} />
                </div>
            </Popup>

            <div className={styles.mainContent}>
                <h1 className={styles.title}>Your Courses</h1>
                <div className={styles.Spinner}><Spinner show={this.state.loading && !this.state.creatingCourse} className={styles.Spinner} /></div>
                <Button id={styles.NewCourseButton} onClick={this.toggleCreateNewCourseHandler}>+ Create Course</Button>
                {(!(this.state.courses.instructing.length > 0) && !(this.state.courses.enrolled.length > 0) && !this.state.loading) ? <p className={styles.Spinner}>You don't have any classes yet</p> : null}
                {this.state.courses.instructing.length > 0 ? 
                (
                <div className={styles.Courses}>
                    {this.state.courses.instructing.map((course, index) => {
                        return <CourseCard key={index} id={course.course_id} name={course.name} code={course.code} description={course.description} active={course.active === '1'} color={course.colour} instructing={true} click={this.courseClickHandler} />
                    })}
                </div>)
                : null}
                {this.state.courses.enrolled.length > 0 ? 
                (
                <div className={styles.Courses}>
                    {this.state.courses.enrolled.map((course, index) => {
                        return <CourseCard key={index} id={course.course_id} name={course.name} code={course.code} description={course.description} active={course.active === '1'} color={course.colour} instructing={false} click={this.courseClickHandler} />
                    })}
                </div>)
                : null}
            </div>
            </>
            
        )
    }
}

// Tell connect Redux which dispatch events we want to call. In this case we want access
// to the add_banner function
const mapDispacthToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

export default connect(null, mapDispacthToProps)(Dashboard);