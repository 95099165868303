import React, {Component} from 'react';
import styles from './tabs.module.css';

/*
-- Tabs component --
Creates clickable tabs that render different content
Props:
- className and/or id: applied to the main content
- onChange: Called when tab is changed. Hands it the currently selected tab index
- disabled: Whether tab selection is enabled
- tabs: an array of tab objects
    - tab: a tab
        - label: the tab's label
        - content: the content of the tab
*/

class Tabs extends Component {
    state = {
        currentTabIndex: 0
    }

    tabHandler = (selectedTabIndex) => {
        if (!this.props.disabled) {
            this.setState({currentTabIndex: selectedTabIndex});
            this.props.onChange(selectedTabIndex);
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.tabsContainer}>
                    {this.props.tabs.map((tab, index) => {
                        return <span key={index} className={styles.tab} id={index === this.state.currentTabIndex ? styles.active : styles.inactive} onClick={() => this.tabHandler(index)}>{tab.label}</span>
                    })}
                </div>
                <div className={`${styles.mainContent} ${this.props.className}`} id={this.props.id}>
                    {this.props.tabs[this.state.currentTabIndex].content}
                </div>
            </div>
        );
    }
}

export default Tabs;