import React from 'react';
import styles from './inputs.module.css';

/*
-- Text Input Component --
The styled text input component
Props:
- className and/or id: to be applied to entire input (label and input)
- disabled: bool representing whether the checkbox is disabled
- value: The input value
- placeholder: The input placeholder text
- name: the input name
- checkHandler: function to be called when the value changes
- type: the input type, defaults to text
- invalidBool: boolean representing whether the input value is invalid
- errorMsg: the error message if invalidBool is true
*/

const TextInput =(props) => {
    return (
        <div className={props.className} id={props.id}>
            <input type={!props.type ? "text" : props.type} name={props.name} value={props.value} placeholder={props.placeholder} className={`${styles.input} ${props.invalidBool ? styles.invalid : null}`} onChange={props.checkHandler} />
            {props.invalidBool ? <span className={styles.error}>{props.errorMsg}</span> : null }
        </div>
    );
}

export default TextInput;