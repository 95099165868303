import React from 'react';
import styles from './inputs.module.css';

/*
-- Labelled Input Component --
The styled labelled input component
Props:
- className and/or id: to be applied to entire input (label and input)
- disabled: bool representing whether the checkbox is disabled
- defaultValue: The default text in the input
- placeholder: The input placeholder text
- name: the input name
- checkHandler: function to be called when the value changes
- label: the input's label
- type: the input type, defaults to text
- invalidBool: boolean representing whether the input value is invalid
- errorMsg: the error message if invalidBool is true
*/

const LabeledInput =(props) => {
    return (
        <div className={`${styles.inputBlock} ${props.className}`} id={props.id}>
            <h3 className={styles.label}>{props.label}</h3>
            <input name={props.name} type={props.type == null ? "text" : props.type} value={props.value} defaultValue={props.defaultValue} placeholder={props.placeholder}
                 className={`${styles.labeledInput} ${props.invalidBool ? styles.invalid : null}`} onChange={props.checkHandler} onBlur={props.checkHandler}/>
            {props.invalidBool ? <span className={styles.error}>{props.errorMsg}</span> : null }
        </div>
    );
}

export default LabeledInput;