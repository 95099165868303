import React, { Component } from 'react';

import Sidebar from '../../components/sidebar/Sidebar';
import UserProfile from './UserProfile';
import Security from './Security';

class Account extends Component {
    render(){
        return(
            <Sidebar title='My Account' links={[
                {"path": "/account/profile", "title": "View your profile", "label": "My Profile", "component": <UserProfile/>},
                {"path": "/account/security", "title": "Account Security Settings", "label": "Login Options", "component": <Security/>}
            ]} />
        );
    };
}

export default Account;