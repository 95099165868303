import React, { Component } from 'react';
import {  Link } from 'react-router-dom';

import axios from 'axios';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import styles from '../login/Login.module.css';
import Spinner from '../../components/UI/loading/Spinner/Spinner';
import TextInput from '../../components/UI/inputs/TextInput.js';
import Checkbox from '../../components/UI/inputs/Checkbox';
import ErrorMsg from '../../components/UI/messages/Fail';
import SubmitBtn from '../../components/UI/buttons/SubmitBtn.js';
import Popup from '../../components/UI/popups/popup';




class Signup extends Component {
    state = {
        loading: false,
        success: false,
        invalidInputs: {
            "username": false,
            "email": false,
            "password": false,
            "secondPassword": false,
            "fname": false,
            "lname": false,
            "tos": false
        },
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        secondPassword: "",
        tosAgreed: false,
        errorMessage: null
    }

    updateInvalidInputs = (key, value) => {
        var temp = this.state.invalidInputs;
        temp[key] = value;
        this.setState({invalidInputs: temp});
    }

    // Handler for when the username input blurs. It calls this.checkUsername
    checkUsernameHandler = (event) => {
        let username = event.target.value;
        this.checkUsername(username);
    }

    // Handler for when the firstname input blurs. It calls this.checkFirstName
    checkFirstNameHandler = (event) => {
        let fname = event.target.value;
        this.checkFirstName(fname);
    }

    // Handler for when the lastname input blurs. It calls this.checkLastName
    checkLastNameHandler = (event) => {
        let lname = event.target.value;
        this.checkLastName(lname);
    }

    // Handler for when the email input blurs. It calls this.checkEmail
    checkEmailHandler = (event) => {
        let email = event.target.value;
        this.checkEmail(email);
    }

    // Handler for when the password input blurs. It calls this.checkPassword
    checkPasswordHandler = (event) => {
        let password = event.target.value;
        this.checkPassword(password);
    }

    // Handler for when the password2 input blurs. It calls this.checkPassword2
    checkSecondPasswordHandler = (event) => {
        let password = event.target.value;
        this.checkSecondPassword(password);
    }

    // Handler for the terms of service input. It updates this.state.tosagreed based on their input
    tosHandler = (event) => {
        this.updateInvalidInputs("tos", !event.target.checked);
        this.setState({tosAgreed: event.target.checked});
        //this.setState({tosAgreed: event.target.checked, invalidTos: !event.target.checked});
    }

    // Function to check the provided username. It updates this.state.username, and updates this.state.invalidUsername to be either true or false
    checkUsername = (username) => {
        const regex = /^[a-zA-Z0-9]{5,30}$/;
        let passed = regex.test(username);
        
        this.updateInvalidInputs("username", !passed);
        this.setState({username: username});

        return passed;
    }

    // Function to check the provided first name. It updates this.state.firstName, and updates this.state.invalidFirstName to be either true or false.
    // Returns whether it is valid or not
    checkFirstName = (fname) => {
        const regex = /^[a-zA-Z]{2,30}$/;
        let passed = regex.test(fname);

        this.updateInvalidInputs("fname", !passed);
        this.setState({firstName: fname});

        return passed;
    }

    // Function to check the provided last name. It updates this.state.lastName, and updates this.state.invalidLastName to be either true or false
    // Returns whether it is valid or not
    checkLastName = (lname) => {
        const regex = /^[a-zA-Z]{2,30}$/;
        let passed = regex.test(lname);
        
        this.updateInvalidInputs("lname", !passed);
        this.setState({lastName: lname});

        return passed;
    }

    // Function to check the provided email. It updates this.state.email, and updates this.state.invalidEmail to be either true or false
    // Returns whether it is valid or not
    checkEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let passed = regex.test(email);
        
        this.updateInvalidInputs("email", !passed);
        this.setState({email: email});

        return passed;
    }


    // Function to check the provided password. It updates this.state.password, and updates this.state.invalidPassword to be either true or false
    // Returns whether it is valid or not
    checkPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,60}$/;
        let passed = regex.test(password);
        
        this.updateInvalidInputs("password", !passed);
        this.setState({password: password});

        return passed;
    }

    checkSecondPassword = (password2) => {
        let passed = password2 === this.state.password;

        this.updateInvalidInputs("secondPassword", !passed);
        this.setState({secondPassword: password2});

        return passed;
    }


    // Function to sign a user up. It checks the fname, lastname, email, and password stored in state, if valid it posts them to the signup API endpoint and waits for it's response. 
    signUpHandler = (event) => {
        event.preventDefault();

        // update this.state.invalidTos
        this.updateInvalidInputs("tos", !this.state.tosAgreed);

        // Update invalild entries in state
        this.checkUsername(this.state.username);
        this.checkEmail(this.state.email);
        this.checkPassword(this.state.password);
        this.checkFirstName(this.state.firstName);
        this.checkLastName(this.state.lastName);
        this.checkSecondPassword(this.state.secondPassword);

        // Check there are no invalid fields
        for (var key in this.state.invalidInputs) {
            if (this.state.invalidInputs[key]) return;
        }

        // Set the loading spinner on
        this.setState({ loading: true});

        // Post the login credentials to /user/login.php. If success, store the JWT token in cookies and call the redux action Login()
        // If it fails, then display an unauthorized message
        axios.post("/user/signup.php", 
        {
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            fname: this.state.firstName,
            lname: this.state.lastName,
            tos: this.state.tosAgreed
        })
        .then(response => {
            // store the JWT token in cookies, and call redux action login()
            //setCookie("token", response.data.token, 1000 * 60 * 60);
            //this.props.login(response.data.user);
            this.setState({loading: false, success: true});
            //this.props.history.push("/"); 
        })
        .catch((error) => {
            // Display the unauthorized message, and turn off the loader
            this.setState({errorMessage: error.response.data.Response, loading: false});
        });
    }

    render() {
        return(
            <div className={styles.loginBox}>
                <Popup show={this.state.success} confirmHandler={() => this.setState({success: false})} confirmText="OK" cancel={() => this.setState({success: false})} title="Success. Confirm Email" allowClickAway={true}>
                    Your account has been created! We've sent you a verification email to your account. You will need to click the link in the email before you can log in.
                </Popup>
                <h1 className={styles.title}>Sign up for PathNOTES</h1>
                <ErrorMsg show={this.state.errorMessage !== null}>{this.state.errorMessage}</ErrorMsg>
                <form onSubmit={this.signUpHandler}>
                    <TextInput name="username" placeholder="Username" value={this.state.username} invalidBool={this.state.invalidInputs["username"]} checkHandler={this.checkUsernameHandler} errorMsg="Please enter a username" />
                    <TextInput name="fname" placeholder="First Name" value={this.state.firstName} invalidBool={this.state.invalidInputs["fname"]} checkHandler={this.checkFirstNameHandler} errorMsg="Please enter your first name" />
                    <TextInput name="lname" placeholder="Last Name" value={this.state.lastName} invalidBool={this.state.invalidInputs["lname"]} checkHandler={this.checkLastNameHandler} errorMsg="Please enter your last name" />
                    <TextInput name="email" type="email" placeholder="Email" value={this.state.email} invalidBool={this.state.invalidInputs["email"]} checkHandler={this.checkEmailHandler} errorMsg="Please enter a valid email" />
                    <TextInput name="password" type="password" placeholder="Password" value={this.state.password} invalidBool={this.state.invalidInputs["password"]} checkHandler={this.checkPasswordHandler} errorMsg="Password must be at least 8 characters and contain at least 1 capital letter and 1 number" />
                    <TextInput name="secondPassword" type="password" placeholder="Type Password Again" value={this.state.secondPassword} invalidBool={this.state.invalidInputs["secondPassword"]} checkHandler={this.checkSecondPasswordHandler} errorMsg="Passwords do not match" />

                    <Checkbox name="TOSandPrivacyAgreed" onChange={this.tosHandler} label={<span>I have read and agree to the <a href="https://pathnotes.com/tos" target="_blank" rel="noopener noreferrer" title="The Pathnotes Terms of Service Agreement">Terms of Service</a> and <a href="https://pathnotes.com/privacy-policy" target="_blank" rel="noopener noreferrer" title="The PathNOTES Privacy Policy">Privacy Policy</a>.</span>} />
                    <ErrorMsg show={this.state.invalidInputs["tos"]}>You must accept the Terms of Service and our Privacy Policy to use PathNOTES.</ErrorMsg>
                    <p><SubmitBtn text='Sign me up!'/></p>
                </form>
                <div>&nbsp;<Spinner show={this.state.loading} />&nbsp;</div>
                <p>Already have an account? <Link to="/login" title="Log in to PathNOTES">Log in</Link> instead.</p>

            </div>
        )
    };
}

// Tell redux connect that we want the loggedIn state as props
const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn
    }
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        login: (user) => dispatch({type: actions.USER_LOGIN, user: user})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);