import React, { Component } from 'react';

import styles from './popup.module.css';
import Backdrop from './Backdrop';
import CloseBtn from '../buttons/CloseBtn';
import SubmitBtn from '../buttons/SubmitBtn';
import Spinner from '../loading/Spinner/Spinner';
import ErrorMsg from '../messages/Fail';
import SuccessMsg from '../messages/Success';

/*
-- Popup component --
Props:
- show: whether or not to show the pop up
- confirmHandler: the function handler for when they click the ok button
- confirmText: the text to display in the ok button
- cancel: the function handler to handle a user clicking cancel (the x) or away from the popup
- title: the title of the popup
- allowClickAway: if true, clicking away from the popup (on the backdrop) closes the popup
- errorMessage: If given, will display the error message
- successMessage: If given, will display the success message
- loading: If loading, will display the loading spinner
- hideSubmit: Bool representing whether to hide the submit button or not
- fixedHeight: Bool representing whether the popup height should be fixed or not
*/

// Class Authors: @Joe Hall and @Nicholas Raye
class popup extends Component {
    confirmHandler = (event) => {
        event.preventDefault();
        if (this.props.confirmHandler !== undefined) this.props.confirmHandler(event);
    }

    render() {
        if(this.props.show) {
            return(
            <>
            <Backdrop show={this.props.show} cancel={this.props.allowClickAway ? this.props.cancel : null} />
            <div className={styles.Popup}>
                <div className={styles.TitleBar}>
                    <CloseBtn id={styles.CloseIcon} onClick={this.props.cancel} />
                    <h2>{this.props.title}</h2>
                </div>
                <form onSubmit={this.confirmHandler}>
                    <div className={`${styles.Content} ${this.props.fixedHeight ? styles.FixedContentHeight : styles.DynamicContentHeight}`}>
                        {this.props.children}
                    </div>
                    <div className={styles.BottomControls}>
                        {this.props.hideSubmit ? null : <SubmitBtn text={this.props.confirmText} disabled={this.props.disableConfirm} />}
                        <ErrorMsg show={this.props.errorMessage !== null} >{this.props.errorMessage}</ErrorMsg>
                        <SuccessMsg show={this.props.successMessage !== null} >{this.props.successMessage}</SuccessMsg>
                        <div>&nbsp;<Spinner show={this.props.loading} />&nbsp;</div>
                    </div>
                </form>
            </div>
            </> 
            );
        } else return null;
    }
}

export default popup;