import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './account.module.css';
import LabeledInput from '../../components/UI/inputs/LabeledInput.js';
import Select from '../../components/UI/inputs/Select.js';
import Checkbox from '../../components/UI/inputs/Checkbox.js';
import Popup from '../../components/UI/popups/popup';
import ErrorMsg from '../../components/UI/messages/Fail';

import * as actions from '../../store/actions';
import authaxios from '../../auth/authaxios';

class ContactPopup extends Component {
    // Set initial State
    state = {
        loading: false,
        validContact: true,
        creatingNewContact: !this.props.contact.hasOwnProperty('contact_id'),
        contact: {
            "contact_id": this.props.contact.contact_id,
            "contact_type": !this.props.contact.hasOwnProperty('contact_type') ? "email" : this.props.contact.contact_type,
            "contact": this.props.contact.contact,
            "preferred_contact": this.props.contact.preferred_contact === "1",
            "verified": this.props.contact.verified === "1"
        },
        errorMessage: null,
        disableSubmit: true
    }

    // Function to update the state contact properties
    updateContactItems = (item, value) => {
        var temp = this.state.contact;
        temp[item] = value;
        this.setState({contact: temp});
    }

    // Function that checks to see if the submit button should be disabled
    // Will set the disableSubmit state variable
    updateDisableSubmit = () => {
        var disableSubmit = !this.state.validContact;

        if (!this.state.creatingNewContact) disableSubmit = disableSubmit || (this.allValuesUnchanged());

        this.setState({disableSubmit: disableSubmit});
    }

    // Returns whether all the input values are unchanged
    allValuesUnchanged = () => {
        return (
            this.props.contact.contact === this.state.contact.contact && this.props.contact.preferred_contact === this.state.contact.preferred_contact
        );
    }

    // Handler for when the submit button is pressed if this is a create contact popup
    createConfirmHandler = (event) => {
        // Check contact detail validity
        if (!this.checkContact(this.state.contact.contact, this.state.contact.contact_type)) {
            this.setState({validContact: false});
            return;
        }

        // turn on loading spinner
        this.setState({loading: true});

        // Send post request with contact information
        authaxios.post("/contact.php", 
        {
            contact_type: this.state.contact.contact_type,
            contact: this.state.contact.contact,
            preferred_contact: this.state.contact.preferred_contact
        })
        .then(response => {
            // Update user contacts prop
            this.props.updateContacts(response.data);

            this.addBannerHandler("success", "Contact successfully added!", true);
            // Call cancel handler to close the popup
            this.props.cancelHandler();
        })
        .catch((error) => {
            // Display the unauthorized message in a banner, and turn off the loader
            this.setState({errorMessage: error.response.data.Response});
            this.addBannerHandler("error", error.response.data.Response, true);
        });

        // turn off the loading spinner
        this.setState({loading: false});
    }

    // Handler for when the submit button is pressed if this is an edit contact popup
    editConfirmHandler = (event) => {
        // Check contact detail validity
        if (!this.checkContact(this.state.contact.contact, this.state.contact.contact_type)) {
            this.setState({validContact: false});
            return;
        }

        // If none of the values have been changed, return
        if (this.allValuesUnchanged()) return;
        
        // Turn on the loading spinner
        this.setState({loading: true});
        
        authaxios.patch(`/contact.php?id=${this.props.contact.contact_id}`, 
        {
            contact: this.state.contact.contact,
            preferred_contact: this.state.contact.preferred_contact
        })
        .then(response => {
            this.props.updateContacts(response.data);
            this.addBannerHandler("success", "Contact successfully changed!", true);

            // turn off the spinner
            this.setState({loading: false});

            this.props.cancelHandler();
        })
        .catch((error) => {
            // Display the unauthorized message, and turn off the loader
            this.setState({errorMessage: error.response.data.Response, loading: false});
            this.addBannerHandler("error", error.response.data.Response, true);
        });
    }

    // Handler for checking the inputted contact
    // Called on onBlur and onChange
    // Also calls updateDisabledSubmit and updates its value and validity in state
    contactHandler = (event) => {
        let contact = event.target.value;
        var passed = this.checkContact(contact, this.state.contact.contact_type);

        this.setState({validContact: passed});
        this.updateContactItems("contact", contact);
        this.updateDisableSubmit();
    }

    // Handler for changing the conact type value in state
    // Called on onChange
    // Also calls updateDisabledSubmit
    contactTypeHandler = (event) => {
        let contactType = event.target.value;
        this.updateContactItems("contact_type", contactType);
        this.updateDisableSubmit();
    }

    // Handler for changing the preferred contact value in state
    // Called on onChange
    // Also calls updateDisabledSubmit
    preferredContactHandler = (event) => {
        this.updateContactItems("preferred_contact", !this.state.contact.preferred_contact);
        this.updateDisableSubmit();
    }

    // Adds a success, warning, or error banner
    addBannerHandler = (type, text, autoClose=false, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        }
        this.props.addBanner(banner);
    }

    // Function to check the provided email
    // Returns whether it is valid or not
    checkContact = (contact, contact_type) => {
        var regex = /(.*), (.*)/;
        if (contact_type === 'email') regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        else if (contact_type === 'phone') regex = /^(\+[0-9]{0,3})?\(([0-9]{3})\)([0-9]{3})-([0-9]{4})$/;
        
        let passed = regex.test(contact);
        return passed;
    }

    render() {
        return(
            <Popup show={true} title={this.state.creatingNewContact ? "Create a Contact" : "Edit Contact"} loading={this.state.loading} confirmText="Confirm" disableConfirm={this.state.disableSubmit}
                 confirmHandler={this.state.creatingNewContact ? this.createConfirmHandler : this.editConfirmHandler} cancel={this.props.cancelHandler} errorMessage={this.state.errorMessage}>
                
                {this.state.creatingNewContact ? 
                    <div className={styles.selectContainer}>
                        <h3>Contact Type</h3>
                        <Select defaultVal={this.state.contact.contact_type} onChange={this.contactTypeHandler}
                             options={[{"label": "Email", "value": "email"}, {"label": "Phone Number", "value": "phone"}]}/>
                    </div>
                : null}

                <LabeledInput label='Contact' name='contact' invalidBool={!this.state.validContact} checkHandler={this.contactHandler}
                     placeholder={this.state.contact.contact_type === 'phone' ? '+1(123)456-7890' : 'someone@email.com'}
                     type={this.state.contact.contact_type} errorMsg='Invalid contact'
                     defaultValue={this.state.contact.contact} />

                {this.state.creatingNewContact ? null : 
                    <>
                        <Checkbox disabled={this.state.contact.contact_type === "phone" || this.props.contact.preferred_contact === "1" || !this.state.contact.verified}
                            label="Primary contact" name='preferred_contact' checked={this.state.contact.preferred_contact} onChange={this.preferredContactHandler} />

                        <ErrorMsg show={this.state.contact.contact_type === "phone"}>Cannot set a phone number as your primary contact.</ErrorMsg>
                        <ErrorMsg show={this.state.contact.contact_type === "email" && !this.state.contact.verified}>You must verify this email before making it your primary contact.</ErrorMsg>
                    </>
                }
            </Popup>
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        updateContacts: (contacts) => dispatch({type: actions.UPDATE_CONTACTS, contacts: contacts}),
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactPopup);