import React, { Component } from 'react';

import styles from './ExpandContainer.module.css';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';

/*
-- ExpandContainer Component --
On Hover, hoverComponents will appear.
Props:
- disabled: Whether this item's hover feature is disabled
- special: boolean representing whether this item is special. If true, will add a star icon
- hoverComponents: array of the components to show on container hover
- takes children components
*/

class ExpandContainer extends Component {
    state = {
        expanded: false
    }

    expandHandler = () => {
        this.setState({expanded: !this.state.expanded});
    }

    render() {
        return (
            <div className={`${this.props.className} ${styles.container}`} id={this.props.id} >
                <div className={`${!this.props.noStyle && this.state.expanded ? styles.bottomBorder : null} ${this.props.noStyle ? null : styles.header}`} >
                    {this.state.expanded ? <FaAngleDown className={styles.expandBtn} onClick={this.expandHandler} /> : <FaAngleRight className={styles.expandBtn} onClick={this.expandHandler} />}
                    <span className={styles.label} >{this.props.label}</span>
                </div>
                <div className={` ${styles.mainContent} ${this.state.expanded ? styles.expanded : styles.closed}`} id={this.props.mainContentId}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ExpandContainer;