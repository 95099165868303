import React from 'react';
import styles from './buttons.module.css';

/*
-- Button Component --
Standard styled button
Props:
- className and/or id: applied to button
- disabled: bool representing whether the button is disabled or not
- onClick: function that happens on button click
- Takes children components
*/

const Button =(props) => {
    return (
        <button className={`${styles.btn} ${props.disabled ? styles.disabledBtn : styles.enabledBtn} ${props.className}`}
             id={props.id} onClick={props.onClick} disabled={props.disabled}>{props.children}</button>
    );
}

export default Button;