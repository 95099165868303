import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';

//import styles from './Course.module.css';
import Popup from '../../../components/UI/popups/popup';
import Tabs from '../../../components/UI/misc/Tabs';

import EditInfo from './EditInfo';
import InviteInstructor from './InviteInstructor';
import InviteStudent from './invite_student';

class ConfigPopup extends Component {
    // Set initial State
    state = {
        loading: false,
        disableSubmit: true,
        errorMsg: "",
        successMsg: "",
        currentTabIndex: 0,
        tabHandlers: [ null, null, null ],  // This holds each tab's submit handlers
        tabSubmitBtnTexts: ["Submit", "Submit", "Submit"] // Each tab's submit button text
    }

    // Function to update the state array of the submit handlers for each tab
    // Takes the handler to update with, and the index of which tab the handler is for
    updateTabHandlers = (index, handler, submitBtnText) => {
        let tempHandlers = this.state.tabHandlers;
        tempHandlers[index] = handler;

        let tempBtnTexts = this.state.tabSubmitBtnTexts;
        tempBtnTexts[index] = submitBtnText;

        this.setState({tabHandlers: tempHandlers, tabSubmitBtnTexts: tempBtnTexts});
    }

    // Function that is called by the EditInfo tab
    // Will add a banner for a success or an error
    // If editInfo was successful, will update the courses props
    updateInfoHandler = (response, success) => {
        if (!success) {
            this.addBannerHandler("error", "Failed to update course information", true);
        } else {
            this.addBannerHandler("success", "Successfully updated course information!", true);
            response.instructors = this.props.course.instructors;
            this.props.updateCourseHandler(response);
        }
    }

    // Handler passed to each tab that handles whether the popup's submit button is disabled or not
    disableSubmit = (disabled) => {
        this.setState({disableSubmit: disabled});
    }

    // Handler passed to each tab that can turn on or off the popup's loading circle
    loadingHandler = (loading) => {
        this.setState({loading: loading});
    }

    // Function that adds the success, warning or error banners
    addBannerHandler = (type, text, autoClose=false, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        }
        this.props.addBanner(banner);
    }

    // Function to update this state array with the EditInfo tab's submit handler
    setEditInfoHandler = (handler, submitBtnText) => {
        this.updateTabHandlers(0, handler, submitBtnText);
    }

    // Function to update this state array with the InviteInstructor tab's submit handler
    setInviteInstructorHandler = (handler, submitBtnText) => {
        this.updateTabHandlers(1, handler, submitBtnText);
    }

    // Function to update this state array with the StudentInvite tab's submit handler
    setInviteStudentHandler = (handler, submitBtnText) => {
        this.updateTabHandlers(2, handler, submitBtnText);
     }

    // Function called when a tab is changed
    // Updates currentTabIndex to the selected tab's index
    onTabChange = (currentTabIndex) => {
        this.setState({errorMsg: "", successMsg: "", currentTabIndex: currentTabIndex});
    }

    render() {
        return(
            <Popup show={true} title="Configure Course" loading={this.state.loading} confirmHandler={this.state.tabHandlers[this.state.currentTabIndex]}
                 confirmText={this.state.tabSubmitBtnTexts[this.state.currentTabIndex]} disableConfirm={this.state.disableSubmit} fixedHeight={true}
                 cancel={this.state.loading ? null : this.props.cancelHandler} errorMessage={this.state.errorMsg} successMessage={this.state.successMsg}>
                <Tabs onChange={this.onTabChange} disabled={this.state.loading} tabs={[
                    {"label": "Edit Course Information", "content":
                        <EditInfo course={this.props.course} loadingHandler={this.loadingHandler} disableSubmit={this.disableSubmit}
                             handler={this.updateInfoHandler} handlerSetter={this.setEditInfoHandler} />
                    },
                    {"label": "Invite Instructors", "content": 
                        <InviteInstructor course={this.props.course} loadingHandler={this.loadingHandler} disableSubmit={this.disableSubmit}
                             handlerSetter={this.setInviteInstructorHandler}/>
                    },
                    {"label": "Invite Students", "content":
                        <InviteStudent courseId={this.props.course.course_id} loadingHandler={this.loadingHandler} disableSubmit={this.disableSubmit}
                             handlerSetter={this.setInviteStudentHandler}  />}
                ]} />
                
            </Popup>
        );
    };
}

// Tell connect Redux which dispatch events we want to call
const mapDispacthToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

export default connect(null, mapDispacthToProps)(ConfigPopup);