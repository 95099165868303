// This file contains any global values that should be accessable accross the application


// The URL for the API
export const apiURL = "https://app.pathnotes.com/api";

// Switch for development mode
export const IS_DEVELOPMENT = false;

// The types of questions and their default parameters
// Please do not name any of the object keys (i.e properties/attributes names) with dashes ( "-"") in them (eg. "my-val" should be called "mayval")
export const questionTypes = {
    TEXT: {
        text: {
            name: "Text",
            defaultValue: "New Question",
            type: "text",
            description: "The text of the question"
        }
    },
    BEAM: {
        length: {
            name: "Length",
            type: "number",
            description: "The length of the beam",
            defaultValue: 10
        },
        pin: {
            name: "Pins",
            type: "array",
            description: "The locations of any pins on the beam",
            item: {
                name: "Pin",
                type: "number",
                description: "A sigle pin on the beam",
                defaultValue: 0
            },
            defaultValue: []
        },
        roller: {
            name: "Rollers",
            type: "array",
            description: "The locations of any rollers on the beam",
            item: {
                name: "Roller",
                type: "number",
                description: "A sigle roller on the beam",
                defaultValue: 10
            },
            defaultValue: [10]
        },
        hinge: {
            name: "Hinges",
            type: "array",
            description: "The locations of any hinges on the beam",
            item: {
                name: "Hinge",
                type: "number",
                description: "A single hinge on the beam",
                defaultValue: 0
            },
            defaultValue: []
        },
        fixed: {
            name: "Fixed",
            type: "number",
            description: "left, right, or both side for fixed point? supported values are 1,2, and 3?",
            defaultValue: 0
        },
        point: {
            name: "Points",
            type: "array",
            description: "Point loads for the beam",
            item: {
                name: "Point Load",
                type: "object",
                description: "A single point load for the beam",
                defaultValue: {position: 8, force: 50, angle: "3:4"},
                value: {
                    position: {
                        name: "Position",
                        type: "number",
                        description: "The position of the point load",
                        defaultValue: 8,
                    },
                    force: {
                        name: "Force",
                        type: "number",
                        description: "The force of the point load",
                        defaultValue: 50,
                    },
                    angle: {
                        name: "Angle",
                        type: "text",
                        description: "The angle (in ratio format) of the point load e.g. 3:4",
                        defaultValue: "3:4",
                    }
                }
            },
            defaultValue: [{position: 8, force: 50, angle: "3:4"}],
        },
        moment: {
            name: "Moments",
            type: "array",
            description: "Moments (torque) for the beam",
            item: {
                name: "Moment",
                type: "object",
                description: "A moment for the beam",
                value: {
                    position: {
                        name: "Position",
                        type: "number",
                        description: "The position of the moment",
                        defaultValue: 5
                    },
                    magnitude: {
                        name: "Magnitude",
                        type: "number",
                        description: "The magnitude and direction of the moment",
                        defaultValue: -50
                    }
                },
                defaultValue: {position: 5, magnitude: -50}
            },
            defaultValue: [{position: 5, magnitude: -50}]
        },
        load: {
            name: "Loads",
            type: "array",
            description: "Loads for the beam",
            item: {
                name: "Load",
                type: "object",
                description: "A load for the beam",
                value: {
                    startPos: {
                        name: "Start Position",
                        type: "number",
                        description: "The start position on the beam for the load",
                        defaultValue: 0
                    },
                    endPos: {
                        name: "End Position",
                        type: "number",
                        description: "The end position on the beam for the load",
                        defaultValue: 4
                    },
                    startForce: {
                        name: "Start Force",
                        type: "number",
                        description: "The amount of force applied at the start position of the load",
                        defaultValue: 50
                    },
                    endForce: {
                        name: "End Force",
                        type: "number",
                        description: "The amount of force applied at the end position of the load",
                        defaultValue: 50
                    }
                },
                defaultValue: {startPos: 0, endPos: 4, startForce: 50, endForce: 50}
            },
            defaultValue: [{startPos: 0, endPos: 4, startForce: 50, endForce: 50}]
        },
        interest: {
            name: "Interests",
            type: "array",
            description: "Any interest points on the beam",
            item: {
                name: "Interest",
                type: "number",
                description: "A single interest point on the beam",
                defaultValue: 0
            },
            defaultValue: []
        },
        unitOfForce: {
            name: "Unit of Force",
            type: "text",
            description: "The unit of force to be used in the graphic",
            defaultValue: "kN"
        },
        unitOfLength: {
            name: "Unit of Length",
            type: "text",
            description: "The unit of length to be used in the graphic",
            defaultValue: "m"
        }
    }
};
