import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import styles from './account.module.css';
import HoverContainer from '../../components/UI/misc/HoverContainer.js';
import AddBtn from '../../components/UI/buttons/AddBtn.js';
import TrashBtn from '../../components/UI/buttons/TrashBtn.js';
import EditBtn from '../../components/UI/buttons/EditBtn.js';
import SubmitBtn from '../../components/UI/buttons/SubmitBtn.js';
import CancelBtn from '../../components/UI/buttons/CancelBtn';
import LabeledInput from '../../components/UI/inputs/LabeledInput.js';
import ContactPopup from './ContactPopup';

import authaxios from '../../auth/authaxios';
import Spinner from '../../components/UI/loading/Spinner/Spinner';

class UserProfile extends Component {
    // Set initial State
    state = {
        formItems: {
            "fname": {"value": this.props.user.fname, "valid": true},
            "lname": {"value": this.props.user.lname, "valid": true}
        },
        deletedContacts: [],
        pendingVerifications: [],
        loading: false,
        showContactPopup: false,
        showEditPopup: true,
        contactToEdit: {},
        nameUpdateFailed: false,
        contactDelFailed: false,
        enableSubmit: false,
    }

    // Function that will update the formItems state object
    updateFormItems = (item, key, value) => {
        var temp = this.state.formItems;
        temp[item][key] = value;
        this.setState({formItems: temp});
    }

    // Handler for the firstname input. It calls this.checkFirstName
    checkFirstNameHandler = (event) => {
        let fname = event.target.value;
        this.checkFirstName(fname);

        if (fname !== this.props.user.fname) {
            this.setState({enableSubmit: true});
        }
    }

    // Handler for the lastname input. It calls this.checkLastName
    checkLastNameHandler = (event) => {
        let lname = event.target.value;
        this.checkLastName(lname);

        if (lname !== this.props.user.lname) {
            this.setState({enableSubmit: true});
        }
    }

    // Handler for when a contact is deleted
    // Adds the given contact's id to the deleted contacts state variable and enables the update button
    onContactDeleteHandler = (contact) => {
        this.setState({deletedContacts: [...this.state.deletedContacts, contact.contact_id]});
        this.setState({enableSubmit: true});
    }

    // Handler for verifying an email
    // Makes POST request to send the verification link to the email
    verifyHandler = (contact) => {
        // turn the loading spinner on
        this.setState({loading: true});

        // Make a POST request with the user's username and contact id
        authaxios.post("/user/verify_email.php", 
        {
            username: this.props.username,
            contact_id: contact.contact_id
        })
        .then(response => {
            // Update the pending verifications array in state and turn off the loading spinner
            this.setState({pendingVerifications: [...this.state.pendingVerifications, contact.contact_id]});
            this.addBannerHandler("success", "Verification email sent!", true);
        })
        .catch((error) => {
            this.addBannerHandler("error", "Verification email failed to send!", true);
        })
        .finally(() => {
            // turn off the loading spinner
            this.setState({loading: false});
        });
    }

    // Function to check the provided first name. It updates this.state.firstName, and updates this.state.invalidFirstName to be either true or false.
    // Returns whether it is valid or not
    checkFirstName = (fname) => {
        const regex = /^[a-zA-Z]{2,30}$/;
        let passed = regex.test(fname);

        this.updateFormItems("fname", "valid", passed);
        this.updateFormItems("fname", "value", fname);

        return passed;
    }

    // Function to check the provided last name. It updates this.state.lastName, and updates this.state.invalidLastName to be either true or false
    // Returns whether it is valid or not
    checkLastName = (lname) => {
        const regex = /^[a-zA-Z]{2,30}$/;
        let passed = regex.test(lname);
        
        this.updateFormItems("lname", "valid", passed);
        this.updateFormItems("lname", "value", lname);

        return passed;
    }

    // Handler for when the update button is pressed
    updateHandler = (event) => {
        event.preventDefault();

        // Update invalild entries in state
        this.checkFirstName(this.state.formItems.fname.value);
        this.checkLastName(this.state.formItems.lname.value);

        // Check there are no invalid fields
        if (!this.state.formItems.fname.valid || !this.state.formItems.lname.valid) return;

        if (this.state.formItems.fname.value !== this.props.user.fname || this.state.formItems.lname.value !== this.props.user.lname || this.state.deletedContacts.length > 0) {
            // If any contacts have been deleted, send a delete request with deleted contact ids
            if (this.state.deletedContacts.length > 0) {
                // Set the loading spinner on
                this.setState({ loading: true});

                authaxios.delete("/contact.php", 
                {
                    data: {
                        contact_ids: this.state.deletedContacts
                    }
                })
                .then(response => {
                    // update user contacts props
                    this.props.updateContacts(response.data);
                    this.setState({contactDelFailed: false, deletedContacts: []});
                })
                .catch((error) => {
                    // Display the unauthorized message, and turn off the loader
                    this.setState({contactDelFailed: true});
                })
                .finally(() => {
                    // Stop the loading spinner
                    this.setState({loading: false});
                });
            }
            
            // If the first or last names have changed, send patch request with updated names
            if (this.state.formItems.fname.value !== this.props.user.fname || this.state.formItems.lname.value !== this.props.user.lname) {
                // Turn the loading spinner on
                this.setState({ loading: true});

                authaxios.patch("/user.php", 
                {
                    fname: this.state.formItems.fname.value,
                    lname: this.state.formItems.lname.value
                })
                .then(response => {
                    // update user props
                    this.props.login(response.data);
                    this.setState({nameUpdateFailed: false});
                })
                .catch((error) => {
                    // Set the unauthorized message
                    this.setState({nameUpdateFailed: true});
                })
                .finally(() => {
                    // Stop the loading spinner
                    this.setState({loading: false});
                });
            }

            if (!this.state.nameUpdateFailed && !this.state.contactDelFailed) {
                this.addBannerHandler("success", "Successfully updated user information", true);
                this.setState({enableSubmit: false});
            } else
                this.addBannerHandler("error", "Failed to update user information", true);
        } else {
            // Stop the loading spinner
            this.setState({loading: false});
        }
    }

    addBannerHandler = (type, text, autoClose=false, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        }
        this.props.addBanner(banner);
    }

    // Handler for when the contact popup is closed
    contactCancelHandler = () => {
        this.setState({showContactPopup: false});
        this.setState({contactToEdit: {}});
    }

    // Handler for when cancel is pressed
    // Clears deletedContacts state array, disables submit
    // Sets fname and lname to their props values
    cancelHandler = () => {
        this.setState({deletedContacts: [], enableSubmit: false});

        this.updateFormItems("fname", "value", this.props.user.fname);
        this.updateFormItems("fname", "valid", true);

        this.updateFormItems("lname", "value", this.props.user.lname);
        this.updateFormItems("lname", "valid", true);
    }

    // Handler that displays the create contact popup
    showCreatePopupHandler = () => {
        this.setState({showContactPopup: true});
    }

    // Handler that displays the edit contact popup
    // Hands the contact that is being edited to the contact popup as a prop 
    showEditPopupHandler = (contactToEdit) => {
        this.setState({contactToEdit: contactToEdit});
        this.setState({showContactPopup: true});
    }

    render() {
        return(
            <div>
                {this.state.showContactPopup ? <ContactPopup cancelHandler={this.contactCancelHandler} contact={this.state.contactToEdit} /> : null}

                <h1>{this.props.user.fname + " " + this.props.user.lname}</h1>

                <div className={styles.mainContent}>
                    <form onSubmit={this.updateHandler}>
                        <LabeledInput label='First Name' name='fname' value={this.state.formItems.fname.value} invalidBool={!this.state.formItems.fname.valid}
                            checkHandler={this.checkFirstNameHandler} errorMsg='Invalid first name'/>
                        <LabeledInput label='Last Name' name='lname' value={this.state.formItems.lname.value} invalidBool={!this.state.formItems.lname.valid}
                            checkHandler={this.checkLastNameHandler} errorMsg='Invalid last name'/>

                        <h3 className={styles.label}><AddBtn id={styles.addIcon} onClick={this.showCreatePopupHandler} />Your Contacts</h3>
                        <h4 className={styles.label}>Emails</h4>
                        <div className={styles.contactContainer}>
                            {this.props.user.contacts.emails.length === 0 ? <p className={styles.label}>You currently have no emails.</p> : this.props.user.contacts.emails.map((contact, index) => {
                                return <HoverContainer key={index} special={contact.preferred_contact === '1'} disabled={this.state.deletedContacts.includes(contact.contact_id)} hoverComponents={[
                                            <TrashBtn onClick={() => this.onContactDeleteHandler(contact)} id={styles.trashIcon} disabled={contact.preferred_contact === '1'} />,
                                            <EditBtn onClick={() => this.showEditPopupHandler(contact)} />,
                                            <span className={styles.verifyStatus}>{contact.verified === '1' ? <span className={styles.verified}>verified</span> : 
                                                this.state.pendingVerifications.includes(contact.contact_id) ? 
                                                    <span className={styles.pending} >pending</span> : <span className={styles.verifyBtn} onClick={this.state.loading ? null : () => this.verifyHandler(contact)} >verify now</span>}
                                            </span>
                                        ]} >
                                            <span>{contact.contact}</span>
                                        </HoverContainer>
                            })}
                        </div>

                        <h4 className={styles.label}>Phone Numbers</h4>
                        <div className={styles.contactContainer}>
                            {this.props.user.contacts.phone_numbers.length === 0 ? <p className={styles.label}>You currently have no phone numbers.</p> : this.props.user.contacts.phone_numbers.map((contact, index) => {
                                return <HoverContainer key={index} special={false} disabled={this.state.deletedContacts.includes(contact.contact_id)} hoverComponents={[
                                                <TrashBtn onClick={() => this.onContactDeleteHandler(contact)} id={styles.trashIcon} />,
                                                <EditBtn onClick={() => this.showEditPopupHandler(contact)} />
                                            ]} >
                                            <span>{contact.contact}</span>
                                        </HoverContainer>
                            })}
                        </div>

                        <br/>
                        {this.state.loading ? <div>&nbsp;<Spinner show={this.state.loading} />&nbsp;</div> : 
                            this.state.enableSubmit ? 
                                <div>
                                    <span className={styles.inlineBtn}><SubmitBtn text='Update' /></span>
                                    <span className={styles.inlineBtn}><CancelBtn id={styles.cancelBtn} onClick={this.cancelHandler} /></span>
                                </div>
                            : null
                        }
                    </form>
                </div>
            </div>
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        login: (user) => dispatch({type: actions.USER_LOGIN, user: user}),
        updateContacts: (contacts) => dispatch({type: actions.UPDATE_CONTACTS, contacts: contacts}),
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);