import React from 'react';

import styles from './inputs.module.css';
const TextArea = ({name, value, placeholder, invalidBool, checkHandler, errorMsg, col, row}) => {
    return (
        <>
        <textarea className={styles.TextArea} name={name} placeholder={placeholder} value={value} onChange={checkHandler} cols={col} rows={row} />
        {invalidBool ? <span className={styles.error}>{errorMsg}</span> : null }
        </>
    );
}

export default TextArea;