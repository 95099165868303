import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import styles from './ResetPassword.module.css';


class ResetPassword extends Component {

  // The initial state for this component
  state = {
    username: "",
    codeSent: false,
    error: "",
    resetingPassword: false,
    newPassword: "",
    newPassword2: "",
    token: "",
    passwordResetSuccess: false
  }

  // When the component mounts, check to see if theres an ID parameter in the URL,
  // If so then set state resetingPassword to true, and state token to the URL parameter
  componentDidMount() {
      if(window.location.search.startsWith("?id=")) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('id');
        this.setState({resetingPassword: true, token: token});
      }
  }

  // Handler for whenever an input changes, sets that inputs value in state
  inputChangedHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  // Checks that the password conforms to our policy and that both passwords match
  checkPassword = () => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,60}$/;
    let passed = regex.test(this.state.newPassword);
    if(!passed) {
      this.setState({error: "Your new password must contain at least 8 characters, and must contatin an uppercase and lowercase character."});
      return false;
    }
    if(this.state.newPassword !== this.state.newPassword2) {
      this.setState({error: "Both passwords must match"});
      return false;
    }
    return true;

  }

  // Handler for when user requests a password reset email.
  // Sends the username to the reset password endpoint
  handleRequestSubmit = (event) => {
    event.preventDefault();

    if(this.state.username === "") {
      this.setState({error: "Please fill in your username"});
      return;
    }

    axios.post('/user/resetpassword.php',
    {
      username: this.state.username
    })
    .then(response => {
      this.setState({codeSent: true});
    })
    .catch(error => {
      this.setState({error: "User does not exist :("})
    });
  };

  // Handler for when the user has visited this page via the link they got in email,
  // it sends the new password along with the token to the API
  handleResetSubmit = (event) => {
    event.preventDefault();
    if(!this.checkPassword()) return;
    axios.patch('/user/resetpassword.php',
    {
      new_password: this.state.newPassword,
      secure_code: this.state.token
    })
    .then(response => {
      this.setState({passwordResetSuccess: true});
    })
    .catch(error => {
      this.setState({error: "Your reset link has expired"});
    })
  };


  render() {
    if(this.state.passwordResetSuccess) {
      return(
        <>
        <h1 className={styles.Title}>Your password has been reset</h1>
        <p className={styles.center}><Link to="/login" title="Log into PathNOTES">Login</Link></p>
        </>
      );
    }
    else if(this.state.resetingPassword) {
      return(
        <>
        <h1 className={styles.Title}>Password Reset</h1>
        <div className={styles.FormBlock}>
        <p className={styles.center}>Please enter your new password below</p>
          {this.state.error !== "" ? <p className={styles.errorMsg}>{this.state.error}</p> : null}
          <form onSubmit={this.handleResetSubmit}>
            <p className={styles.center}>
              <input type="text" name="newPassword" value={this.state.newPassword} onChange={this.inputChangedHandler} placeholder="New Password" />
            </p>
            <p className={styles.center}>
              <input type="text" name="newPassword2" value={this.state.newPassword2} onChange={this.inputChangedHandler} placeholder="Re-type New Password" />
            </p>
            <p className={styles.center}><input className={styles.button} type="submit" value="Update Password" /></p>
          </form>
        </div>
        </>
      );
    }
    else if(!this.state.codeSent) {
      return(
        <>
        <h1 className={styles.Title}>Forgot your password?</h1>
        <div className={styles.FormBlock}>
        <p className={styles.center}>Enter your username below and we'll send you an account recouvery email.</p>
          {this.state.error !== "" ? <p className={styles.errorMsg}>{this.state.error}</p> : null}
          <form onSubmit={this.handleRequestSubmit}>
            <p className={styles.center}>
              <input type="text" name="username" value={this.state.username} onChange={this.inputChangedHandler} placeholder="Username" />
            </p>
            <p className={styles.center}><input className={styles.button} type="submit" value="Send Reset Email" /></p>
          </form>
        </div>
        </>
      );
    } else {
      return <h1 className={styles.Title}>Success! Check your email</h1>
    }
  }
}

export default ResetPassword;