import React from 'react';
import styles from './buttons.module.css';

/*
-- CancelBtn Component --
styled cancel button
Props:
- className: applied to button
- disabled: bool representing whether the button is disabled or not
- onClick: function that happens on button click
*/

const CancelBtn =(props) => {
    return (
        <button className={`${styles.btn} ${props.disabled ? styles.disabledBtn : styles.enabledBtn} ${props.className}`}
             id={styles.cancelBtn} onClick={props.onClick} disabled={props.disabled}>Cancel</button>
    );
}

export default CancelBtn;