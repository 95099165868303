import React, { Component } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';

import styles from './Sidebar.module.css';
import BackBtn from '../UI/buttons/BackBtn';
import ExpandContainer from '../UI/misc/ExpandContainer';

/*
-- Side bar component --
On mobile, will disappear and show an arrow to reopen it
Props:
- className and/or id: applied to entire sidebar
- title: the title of the sidebar
- links: array of the link objects. Links represent the navigation links to
         components to appear on the sidebar
    - link: object containing the following
        - path: the relative path to the component
        - label: what will show up on the sidebar to represent link
        - title: the text that shows up on hover
        - component: the component the link is linked to
    
- takes children components
*/

class Sidebar extends Component {
    state = {
        isOpen: true
    }

    close = () => {
        this.setState({isOpen: false});
    }

    open = () => {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isOpen ? null : <BackBtn id={styles.openDrawerButton} onClick={this.open} />}
                <div className={`${styles.Sidebar} ${this.state.isOpen ? styles.isOpen : styles.isClosed} ${this.props.className}`} id={this.props.id}>
                    <h2>{this.props.title}</h2>

                    {!this.props.links ? null : this.props.links.map((link, index) => {
                        if (link.childLinks === undefined) {
                            return <Link key={index} to={link.path} title={link.title} onClick={this.close}>{link.label}</Link>
                        } else {
                            return (
                                <ExpandContainer key={index} id={styles.dropdownLink} mainContentId={styles.dropdownLinkContent} noStyle={true}
                                     label={<Link key={index} id={styles.dropdownLinkLabel} to={link.path} title={link.title} onClick={this.close}>{link.label}</Link>} 
                                >
                                    {link.childLinks.map((link, index) => {
                                        return <Link key={index} to={link.path} title={link.title} onClick={this.close}>{link.label}</Link>
                                    })}
                                </ExpandContainer>
                            );
                        }
                    })}
                    
                    {this.props.children}
                </div>
                <div className={styles.MainContent}>
                    {!this.props.links ? null : this.props.links.map((link, index) => {
                        if (link.childLinks === undefined) {
                            return <Route key={index} path={link.path} exact render={(props) => link.component} />
                        } else {
                            return (
                                <div key={index}>
                                    <Route key={index} exact path={link.path} render={(props) => link.component} />
                                    {link.childLinks.map((link, index) => {
                                        return <Route key={index} exact path={link.path} render={(props) => link.component} />
                                    })}
                                </div>
                            );
                        }
                    })}
                    
                </div>
            </React.Fragment>
        );
    };
    
};

export default withRouter(Sidebar);