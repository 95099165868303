import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import axios from 'axios';
import { connect } from 'react-redux';
import { IS_DEVELOPMENT } from '../../Globals';


import * as actions from '../../store/actions';
import styles from './Login.module.css';
import TextInput from '../../components/UI/inputs/TextInput.js';
import SubmitBtn from '../../components/UI/buttons/SubmitBtn.js';
import Spinner from '../../components/UI/loading/Spinner/Spinner';
import Popup from '../../components/UI/popups/popup';

class Login extends Component {

    state = {
        loading: false,
        unauthorized: "",
        invalidUsername: false,
        invalidPassword: false,
        username: "",
        password: "",
        authSuccessful: false,
        emailNotVerifiedMessage: false,
        emailLoad: false,
        emailSuccess: "",
        emailError: "",
        thenURL: "/"
    }
    componentDidMount() {
        // If login was provided with a then parameter, set the thenURL to that. If their are additional query parameters then add those to the thenURL
        // ?then=RELATIVE_URL_TO_GO_TO_AFTER
        // &qp=THE QUERY PARAMETER NAME TO ADD
        // &q= THE QUERY VALUE TO ADD
        // eg. login?then=/invite&qp=i&q=mytoken  == REDIRECT after login to: /invite?i=mytoken
        if(window.location.search.startsWith("?then=")) {
            const urlParams = new URLSearchParams(window.location.search);
            let thenURL = urlParams.get('then');
            const queryParameter = urlParams.get('qp');
            const query = urlParams.get('q');
            if(queryParameter && query) thenURL += `?${queryParameter}=${query}`;
            this.setState({thenURL: thenURL});
        }
    }


    // Handler for when the email input blurs. It calls this.checkEmail
    checkUsernameHandler = (event) => {
        let username = event.target.value;
        this.checkUsername(username);
    }

    // Handler for when the password input blurs. It calls this.checkPassword
    checkPasswordHandler = (event) => {
        let password = event.target.value;
        this.checkPassword(password);
    }


    // Function to check the provided username. It updates this.state.username, and updates this.state.invalidUsername to be either true or false
    checkUsername = (username) => {
        const regex = /^[a-zA-Z0-9]{5,30}$/;
        let passed = regex.test(username);
        this.setState({invalidUsername: !passed, username: username});
        return passed;
    }


    // Function to check the provided password. It updates this.state.password, and updates this.state.invalidPassword to be either true or false
    checkPassword = (password) => {
        // TODO: alter this regex to match whatever we decide our password policy is. right now it's 8-60 chars, one num, one caps, on lowercase - AND NOTHING ELSE
        // should alter it to allow special chars
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,60}$/;
        let passed = regex.test(password);
        this.setState({invalidPassword: !passed, password: password});
        return passed;
    }


    // Function to log a user in. It checks the email and password stored in state, if valid it posts them to the login API endpoint and waits for it's response.
    // If valid, it will call the redux action Login. If not it will display the Invalid Credentials message
    loginHandler = (event) => {
        event.preventDefault();
        
        // Check first that the email is valid and the password conforms to our policy, if not, display errors.
        if(!this.checkUsername(this.state.username) || !this.checkPassword(this.state.password)) {
            return;
        }
        // Set the loading spinner on, and remove previous Unathorized message if was present
        this.setState({loading: true, unauthorized: ""});

        // Post the login credentials to /user/login.php. If success, store the JWT token in cookies and call the redux action Login()
        // If it fails, then display an unauthorized message
        axios.post("/user/login.php", 
        {
            username: this.state.username,
            password: this.state.password
        })
        .then(response => {
            // store the JWT token in cookies, and call redux action login()
            if (IS_DEVELOPMENT) localStorage.setItem("token", response.data.token);
            this.props.login(response.data.user);
        })
        .catch((error) => {
            // Display the unauthorized message, and turn off the loader
            if (error.response === undefined) {
                this.setState({unauthorized: "Cannot reach server", loading: false});
            } else if(error.response.status === 403) {
                this.setState({unauthorized: "Email not verified", loading: false, emailNotVerifiedMessage: true});
            } else {
                this.setState({unauthorized: "Invalid Credentials", loading: false});
            }
        });
    }

    // Function for resending the verify-email email.
    // will send a network request to the verify-email endpoint
    sendNewConfirmationHandler = (event) => {
        event.preventDefault();
        this.setState({emailLoad: true});
        axios.post('/user/verify_email.php', {
            username: this.state.username
        })
        .then(response => {
            this.setState({emailLoad: false, emailSuccess: "Email Sent", emailError: "", emailNotVerifiedMessage: false});
            let banner = {
                type: "success",
                text: "Verification Email Sent",
                link: "",
                linkText: "",
                autoClose: true
            };
            this.props.addBanner(banner);
        })
        .catch(error => {
            this.setState({emailLoad: false, emailSuccess: "", emailError: error.response.data.Response});
            let banner = {
                type: "error",
                text: "An error occured when sending the confirmation email",
                link: "",
                linkText: "",
                autoClose: true
            };
            this.props.addBanner(banner);
        });
    }


    render(){
        // The redux state.loggedIn is true, then never display the login page and redirect to /dashboard
        // This will also serve as a redirect for when the user successfully logs in via this page.
        if(this.props.loggedIn) {
            return <Redirect to={this.state.thenURL} />
        }
        return(    
            <div className={styles.loginBox}>
                <Popup show={this.state.emailNotVerifiedMessage} confirmHandler={this.sendNewConfirmationHandler} confirmText="Send New Email" cancel={() => this.setState({emailNotVerifiedMessage: false})} allowClickAway={false} title="Email Not Verified" loading={this.state.emailLoad} successMessage={this.state.emailSuccess} errorMessage={this.state.emailError}>
                    You can not log in until you've verified your email. Would you like us to resend your verification email?
                </Popup>
                <h1 className={styles.title}>Login to PathNOTES</h1>
                <span className={styles.error}>{this.state.unauthorized !== "" ? this.state.unauthorized : null}&nbsp;</span>
                <form onSubmit={this.loginHandler}>
                    <TextInput name="username" placeholder="Username" invalidBool={this.state.invalidUsername} value={this.state.username} checkHandler={this.checkUsernameHandler} errorMsg="Please enter a valid username." />
                    <TextInput name="password" type="password" placeholder="Password" invalidBool={this.state.invalidPassword} value={this.state.password} checkHandler={this.checkPasswordHandler} errorMsg="Please enter a valid password." />
                    <p><SubmitBtn text='Login' /></p>
                </form>
                <div>&nbsp;<Spinner show={this.state.loading} />&nbsp;</div>
                <Link to="/reset/password" title="Click here to reset your password">Forgot password?</Link>
                <p>Don't have an account? <Link to="/signup" title="Sign up for PathNOTES">Sign up</Link> instead.</p>
            </div>
        )
    };
}

// Tell redux connect that we want the loggedIn state as props
const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn
    }
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        login: (user) => dispatch({type: actions.USER_LOGIN, user: user}),
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);