import React from 'react';
import styles from './inputs.module.css';

const Select = (props) => {
    return (
        <select className={`${styles.customSelect} ${props.className}`} id={props.id} value={props.defaultVal} onChange={props.onChange}>
            {props.options.map((option, index) => {
                return <option key={index} className={`${styles.selectOption} ${option.className}`} id={option.id} value={option.value}>{option.label}</option>
            })}
        </select>
    );
}

export default Select;