import React, { Component } from 'react';
//import { connect } from 'react-redux';

//import styles from './Course.module.css';

import LabeledInput from '../../../components/UI/inputs/LabeledInput.js';
import LabeledTextArea from '../../../components/UI/inputs/LabeledTextArea.js';
import Checkbox from '../../../components/UI/inputs/Checkbox';

import authaxios from '../../../auth/authaxios';

class EditInfo extends Component {
    // Set initial State
    state = {
        course: {
            code: {value: this.props.course.code, valid: true},
            name: {value: this.props.course.name, valid: true},
            description: {value: this.props.course.description, valid: true},
            active: {value: this.props.course.active === '1', valid: true},
            colour: {value: this.props.course.colour, valid: true}
        }
    }

    // Function to update the state course properties
    updateCourseItems = (item, prop, value) => {
        var temp = this.state.course;
        temp[item][prop] = value;
        this.setState({course: temp});
    }

    // Function that checks to see if the submit button should be disabled
    updateDisabledSubmit = () => {
        // Logic to determine if submit button should be disabled
        let allValid = this.state.course.code.valid && this.state.course.name.valid && this.state.course.description.valid;
        let allValuesUnchanged = this.areAllValuesUnchanged();
        
        // Returns and disables submit button based on logic
        this.props.disableSubmit(!allValid || allValuesUnchanged);
        return (!allValid || allValuesUnchanged);
    }

    // Determines if all the course info has changed or not
    areAllValuesUnchanged = () => {
        for (const property in this.state.course) {
            if (property === "active") {
                if (this.state.course[property].value !== (this.props.course[property] === '1')) return false;
            } else {
                if (this.state.course[property].value !== this.props.course[property]) return false;
            }
        }

        return true;
    }


    // Handler for checking the inputted course code
    // Called onBlur and onChange
    // Also calls updateDisabledSubmit and updates its value and validity in state
    checkCodeHandler = (event) => {
        let code = event.target.value;
        this.updateCourseItems("code", "value", code);
        const valid = this.checkCode(code);
        this.updateCourseItems("code", "valid", valid)
        this.updateDisabledSubmit();
    }

    // Handler for checking the inputted course name
    // Called onBlur and onChange
    // Also calls updateDisabledSubmit and updates its value and validity in state
    checkNameHandler = (event) => {
        let name = event.target.value;
        const valid = this.checkName(name);
        this.updateCourseItems("name", "value", name);
        this.updateCourseItems("name", "valid", valid);
        this.updateDisabledSubmit();
    }

    // Handler for checking the inputted course description
    // Called onBlur and onChange
    // Also calls updateDisabledSubmit and updates its value and validity in state
    checkDescHandler = (event) => {
        let description = event.target.value;
        const valid = this.checkDesc(description);
        this.updateCourseItems("description", "value", description);
        this.updateCourseItems("name", "valid", valid);
        this.updateDisabledSubmit();
    }

    // Handler for changing the course active value in state
    // Called on onChange
    // Also calls updateDisabledSubmit
    activeHandler = (event) => {
        this.updateCourseItems("active", "value", !this.state.course.active.value);
        this.updateDisabledSubmit();
    }

    // Handler for changing the course color value in state
    // Called on onChange
    // Also calls updateDisabledSubmit
    colourHandler = (event) => {
        let colour = event.target.value;
        this.updateCourseItems("colour", "value", colour);
        this.updateDisabledSubmit();
    }

    // Checks course code validity
    checkCode = (code) => {
        var regex = /^([a-zA-Z]{2,5})([a-zA-Z\d]{2,3})$/;
        let passed = regex.test(code);
        return passed;
    }

    // Checks course name validity
    checkName = (name) => {
        var regex = /^([a-zA-Z\s]{4,45})$/;
        let passed = regex.test(name);
        return passed;
    }

    // Checks course description validity
    checkDesc = (description) => {
        let passed = description.length >= 5;
        return passed;
    }

    // Handler for when submit is pressed
    // Makes PATCH request to API with the updated course information
    submitHandler = (event) => {
        // If the submit button should be disabled, return
        if (this.updateDisabledSubmit()) return;
        
        // Turn on the popup's loading spinner
        this.props.loadingHandler(true);
        
        // Make PATCH request to API providing the course id
        authaxios.patch(`/course.php?id=${this.props.course.course_id}`, 
        {
            code: this.state.course.code.value,
            name: this.state.course.name.value,
            description: this.state.course.description.value,
            active: this.state.course.active.value,
            colour: this.state.course.colour.value
        })
        .then(response => {
            this.props.disableSubmit(true);
            this.props.handler(response.data, true);
        })
        .catch((error) => {
            this.props.handler(error.response.data.Response, false);
        })
        .finally(() => {
            // turn off the loading spinner
            this.props.loadingHandler(false);
        });
    }

    // Sets the submit handler in the parent popup
    componentDidMount() {
        this.props.handlerSetter(this.submitHandler, "Update");
    }

    render() {
        return(
            <div>
                <LabeledInput label='Course Code' name='code' defaultValue={this.props.course.code} invalidBool={!this.state.course.code.valid}
                    checkHandler={this.checkCodeHandler} errorMsg='Course code must contain at least 3 letters and 3 numbers.'/>
                <LabeledInput label='Course Name' name='name' defaultValue={this.props.course.name} invalidBool={!this.state.course.name.valid}
                    checkHandler={this.checkNameHandler} errorMsg='Name must contain only letters and be 4-45 characters in length.'/>
                <LabeledTextArea label='Course Description' name='code' defaultValue={this.props.course.description} invalidBool={!this.state.course.description.valid}
                    checkHandler={this.checkDescHandler} errorMsg='Description must have at least 5 characters.' rows={3} />
                <LabeledInput label='Course Colour' type='color' name='colour' value={this.state.course.colour.value} checkHandler={this.colourHandler} />
                <Checkbox label="Is active" name='active' checked={this.state.course.active.value} onChange={this.activeHandler} />
            </div>
        );
    };
}

export default EditInfo;