import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import authaxios from '../../auth/authaxios';
import * as actions from '../../store/actions';

import styles from './Course.module.css';

import Spinner from '../../components/UI/loading/Spinner/Spinner';
import HoverContainer from '../../components/UI/misc/HoverContainer';
import TrashBtn from '../../components/UI/buttons/TrashBtn.js';
import EditBtn from '../../components/UI/buttons/EditBtn.js';
import AddBtn from '../../components/UI/buttons/AddBtn.js';
import SubmitBtn from '../../components/UI/buttons/SubmitBtn';
import CancelBtn from '../../components/UI/buttons/CancelBtn';
import AddMaterialPopup from './AddMaterial';

class CourseMaterial extends Component {
    state = {
        loading: false,
        selectedMaterialIndex: null,
        showPopup: false,
        deletedMaterial: []
    }

    // Called when a course material is deleted
    // Adds the deleted material's index to the state array
    onMaterialDelHandler = (index) => {
        this.setState({deletedMaterial: [...this.state.deletedMaterial, index]});
    }

    // Shows the edit popup and sets the selected course material
    showEditPopupHandler = (selectedMaterialIndex) => {
        this.setState({showPopup: true, selectedMaterialIndex: selectedMaterialIndex});
    }

    // Shows the create popup
    showCreatePopupHandler = () => {
        this.setState({showPopup: true, selectedMaterialIndex: null});
    }

    // Closes the popup
    closePopupHandler = () => {
        this.setState({showPopup: false});
    }

    // Called when the confirm button is pressed
    // Deletes any course material in the deletedMaterial state array
    confirmMaterialDelHandler = () => {
        this.state.deletedMaterial.forEach(materialIndex => {
            let material = this.props.courseMaterial[materialIndex];
            authaxios.delete(`/course/material.php?material_id=${material.material_id}`)
            .then(response => {
                this.props.updateCourseMaterial(null, materialIndex, "DELETE");
    
                this.addBannerHandler("success", "Selected course material successfully deleted!", true);
            })
            .catch(error => {
                this.addBannerHandler("error", "Failed to delete selected course material", true);
            });
        });

        this.setState({loading: false, deletedMaterial: []});
    }

    // NOT USED CURRENTLY
    // If cancel button button was implemented, would cancel the any deletes
    cancelMaterialDelHandler = () => {
        this.setState({deletedMaterial: []});
    }

    addBannerHandler = (type, text, autoClose=false, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        }
        this.props.addBanner(banner);
    }

    render() {
        if (this.state.loading) {
            return(
                <div>
                    <h1>Course Content</h1>
                    <Spinner show={true} />
                </div>
            );
        } else {
            return(
                <div>
                    {this.state.showPopup ? 
                        <AddMaterialPopup cancelHandler={this.closePopupHandler} material={this.props.courseMaterial[this.state.selectedMaterialIndex]}
                             creatingNewMaterial={this.state.selectedMaterialIndex === null} updateMaterial={this.props.updateCourseMaterial}
                             course_id={this.props.courseId} selectedMaterialIndex={this.state.selectedMaterialIndex} /> 
                    : null}
                    <h1>Course Content</h1>
                    <div className={styles.mainContent} >
                        {this.props.instructing ?
                            <div>
                                <AddBtn id={styles.addIcon} onClick={this.showCreatePopupHandler} />
                                <span> Add course material</span>
                            </div>
                        : null}

                        <div className={styles.materialContainer} >
                            {this.props.courseMaterial.length > 0 ? this.props.courseMaterial.map((material, index) => {
                                return (
                                    <HoverContainer key={index} disabled={this.state.deletedMaterial.includes(index)} hoverComponents={this.props.instructing ? [
                                        <TrashBtn id={styles.trashIcon} onClick={() => this.onMaterialDelHandler(index)} />,
                                        <EditBtn onClick={() => this.showEditPopupHandler(index)} />,
                                        <span className={styles.dueDate}>Due {material.due_date}</span>
                                    ] : []} >
                                        <Link to={`/c/${material.course_id}/material/${material.material_id}`}>{material.name}</Link>
                                    </HoverContainer>
                                );
                            }) : 
                                <p>There is currently no content for this course.</p>
                            }
                        </div>
                        {this.state.loading ? <div>&nbsp;<Spinner show={this.state.loading} />&nbsp;</div> : 
                            this.state.deletedMaterial.length > 0 ? 
                                <div>
                                    <span className={styles.inlineBtn}><SubmitBtn text='Delete Selected' onClick={this.confirmMaterialDelHandler} /></span>
                                    <span className={styles.inlineBtn}><CancelBtn id={styles.cancelBtn} onClick={this.cancelMaterialDelHandler} /></span>
                                </div>
                            : null
                        }
                    </div>
                </div>
            );
        }
    };
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

export default connect(null, mapDispatchToProps)(CourseMaterial);