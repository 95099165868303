import * as actionTypes from './actions';

import { IS_DEVELOPMENT } from '../Globals';

// the intial state of REDUX
const initialState = {
    user: null,
    token: null,
    loggedIn: false,
    banners: []
};

// This is the redux reducer, it holds all the logic for redux actions
// If you add more actions, add a constant first in /store/actions.js, then use this switch
// statement to add the logic for that action
// NOTES:
// All actions here need to be SYNCHRONOUS - meaning no network calls, or any asynchronous tasks
// If we need that we will have to add some enhancers to redux to allow for it
// 
// All actions must return a NEW state object, meaning no modifying the old state, you need to perform a copy of the old
// state to create the new one - NOT a shallow copy, a deep copy
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOGIN:
            return {
                ...state,
                user: action.user,
                loggedIn: true
            }
        case actionTypes.USER_LOGOUT:
            if (IS_DEVELOPMENT) localStorage.removeItem("token");
            
            return {
                ...state,
                user: null,
                token: null,
                loggedIn: false
            }
        case actionTypes.UPDATE_CONTACTS:
            let contacts = action.contacts;
            let user = {...state.user};
            user.contacts = contacts;

            return {
                ...state,
                user: user
            }
        case actionTypes.ADD_BANNER:
            let banners = [...state.banners];
            banners.push(action.banner);
            return {
                ...state,
                banners: banners
            }
        case actionTypes.REMOVE_BANNER:
            let bannersArray = [...state.banners];
            if(bannersArray.length === 0 || action.index >= bannersArray.length) {
                console.error("An internal error has occured with the banner. Attempted to remove a banner when none were present, or attempted to remove a banner index that doesn't exist");
                return {
                    ...state
                }
            }
            const filteredItems = bannersArray.slice(0, action.index).concat(bannersArray.slice(action.index + 1, bannersArray.length));
            return {
                ...state,
                banners: filteredItems
            }
        default:
            return {
                ...state
            }
    }
};

export default reducer;