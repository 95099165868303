import React from 'react';
import styles from './HoverContainer.module.css';
import { FaStar } from 'react-icons/fa';

/*
-- HoverContainer Component --
On Hover, hoverComponents will appear.
Props:
- disabled: Whether this item's hover feature is disabled
- special: boolean representing whether this item is special. If true, will add a star icon
- hoverComponents: array of the components to show on container hover
- takes children components
*/

const hoverContainer =(props) => {
    return (
        <div className={`${styles.container} ${props.disabled ? styles.disabled : styles.hoverContainer} ${props.onClick ? styles.clickable : null}`} onClick={props.onClick}>
            {props.hoverComponents.map((component, index) => {
                return <span key={index} className={styles.btnToShow}>{component}</span>
            })}
            {props.special ? <FaStar id={styles.specialIcon} /> : null}
            <span>{props.children}</span>
        </div>
    );
}

export default hoverContainer;