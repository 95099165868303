import React from 'react';
import styles from './inputs.module.css';

/*
-- Labelled Input Component --
The styled labelled input component
Props:
- className and/or id: to be applied to entire input (label and input)
- disabled: bool representing whether the checkbox is disabled
- defaultValue: The default text in the input
- placeholder: The input placeholder text
- name: the input name
- checkHandler: function to be called when the value changes
- label: the input's label
- type: the input type, defaults to text
- invalidBool: boolean representing whether the input value is invalid
- errorMsg: the error message if invalidBool is true
- cols: number of columns in the text area
- rows: number of rows in the text area
*/

const LabeledTextArea =(props) => {
    return (
        <div className={props.className} id={props.id}>
            <h3 className={styles.label}>{props.label}</h3>
            <textarea name={props.name} type={props.type == null ? "text" : props.type} defaultValue={props.defaultValue}
                 placeholder={props.placeholder} className={`${styles.labeledInput} ${props.invalidBool ? styles.invalid : null}`}
                 onChange={props.checkHandler} onBlur={props.checkHandler} row={props.rows} cols={props.cols} />
            {props.invalidBool ? <span className={styles.error}>{props.errorMsg}</span> : null }
        </div>
    );
}

export default LabeledTextArea;