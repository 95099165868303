import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import authaxios from '../auth/authaxios';

import styles from './document.module.css';
import Spinner from '../components/UI/loading/Spinner/Spinner';
import CourseCard from '../pages/dashboard/CourseCard';
import Button from '../components/UI/buttons/Button';
import ErrorMsg from '../components/UI/messages/Fail';
import * as actions from '../store/actions';

class Invite extends Component {

    state = {
        loading: false,
        token: null,
        errorMessage: null,
        course: null
    }

    
    componentDidMount() {
        // If there is a query parameter for course invite token, else redirect to /
        if(window.location.search.startsWith("?i=")) {
            // Extract the token from the URL and update state
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('i');
            this.setState({token: token});
            // If the user is logged in then try to fetch info about the course using the token and store the info in state
            if(this.props.loggedIn) {
                this.setState({loading: true});
                authaxios.get(`/course/invite.php?token=${token}`)
                .then(response => {
                    this.setState({course: response.data, loading: false});
                })
                .catch(error => {
                    this.setState({course: null, loading: false, success: false, errorMessage: error.response.data.Response});
                });
            }
        } else {
            this.props.history.push("/");
            return;
        }
    }

    // Handler for when the user clicks "join course". Patches to the API to accept the course,
    // if success it redirects to dashboard and puts up a banner, otherwise it displays the error message
    acceptInvitationHandler = () => {
        this.setState({loading: true});
        authaxios.patch(`/course/invite.php?token=${this.state.token}`)
        .then(response => {
            const banner = {
                type: "success",
                link: "",
                linkText: "",
                text: `${this.state.course.course.course_code}:${this.state.course.course.course_name} has been added to your courses`,
                autoClose: true
            }
            this.props.addBanner(banner);
            this.props.history.push("/");
        })
        .catch(error => {
            this.setState({loading: false, errorMessage: error.response.data.Response});
        });
    }

    // function to take an array of strings and returns one comma delimeted and 'and' list
    // eg: array= ['john', 'susan', 'markus'] RETURNS: "john, susan and markus"
    commaAndAndList = (list) => {
        if(list.length === 0) return "";
        if(list.length === 1) return list[0];
        const last = list.pop();
        return list.join(', ') + ' and ' + last;
    }

    render() {
        
        return(
            <>
                <h1 className={styles.h1}>Course Invitation</h1>
                <ErrorMsg message={this.state.errorMessage} className={styles.center} show={this.state.errorMessage} />
                <div className={styles.center}><Spinner show={this.state.loading} /></div>
                {!this.props.loggedIn ? <><p className={styles.center}>Before you can accept this request you must first <Link to={"/login?then=/invite&qp=i&q=" + this.state.token} title="Log into PathNOTES">Login</Link>.</p> 
                <p className={styles.center}>If you don't have a PathNOTES account then <Link to="/signup" title="Sign up for PathNOTES">Sign-up</Link> instead. If you sign-up you will need to click the link in your email again.</p></> : null}
                {this.state.course ? 
                <>
                    <p className={styles.center}>You've been invited to join {this.commaAndAndList(this.state.course.instructors)}'s course.</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}><CourseCard id="" name={this.state.course.course.course_name} active={true} code={this.state.course.course.course_code}
                    description={this.state.course.course.course_description} color="green" instructing={false} click={null} /></div>
                </>
                : null}
                {this.state.course ? <p className={styles.center}><Button disabled={false} onClick={this.acceptInvitationHandler}>Join Course</Button></p> : null}
            </>
        )
    }
}

// Tell redux connect that we want the loggedIn state as props
const mapStateToProps = state => {
    return {
        loggedIn: state.loggedIn
    }
}

// Tell connect Redux which dispatch events we want to call
const mapDispacthToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

export default connect(mapStateToProps, mapDispacthToProps)(Invite);