import React from 'react';

import styles from './fullpageload.module.css';
import Spinner from '../Spinner/Spinner';

// Function based component for showing a full page load. 
// It expects props.show (boolean) to say whether it should show or not.
// AUTHOR: @Joe Hall
const fullpageload = (props) => (
    props.show ? <div className={styles.backdrop}><h1 className={styles.title}>PathNOTES</h1><p className={styles.loading}>loading...</p><div className={styles.loading}><Spinner show={true} /></div></div> : null
);

export default fullpageload;