import React from 'react';
import styles from './buttons.module.css';
import { FaPlus } from 'react-icons/fa';

/*
-- Add Icon Button --
The plus icon button
Props:
- className and/or id: applied to button
- disabled: bool representing whether the button is disabled or not
- onClick: function that happens on button click
*/

const AddBtn =(props) => {
    return (
        <FaPlus className={`${styles.iconBtn} ${props.disabled ? styles.disabledBtn : styles.enabledBtn} ${props.className}`}
                id={props.id} onClick={props.disabled ? null : props.onClick} />
    );
}

export default AddBtn;