import React from 'react';

import styles from './CourseCard.module.css';

// Function based Component for displaying a course card
// Component Author: @Joe Hall
const CourseCard = ({id, name, code, description, active, color, instructing, click}) => {

    // Handler for when the user clicks on the card, if provided with 
    // a click prop, then it will call that.
    const courseClickHandler = () => {
        if(click) click(id);
    }

    return(
        <div className={styles.Card} style={{backgroundColor: color}} onClick={courseClickHandler}>
            <div className={styles.CardTop}>
                <span className={active ? styles.CourseStatus + " " + styles.active : styles.CourseStatus}>{active ? "Active" : "Inactive"}</span>
                <span className={styles.Title}>{code}: {name}</span>
            </div>
            <div className={styles.CardMiddle}>
                <span className={styles.description}>{description}</span>
            </div>
            {instructing ?
            <div className={styles.CardBottom}>INSTRUCTOR</div> : null}
        </div>
    )
};

export default CourseCard;