import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabeledInput from '../../components/UI/inputs/LabeledInput.js';
import Popup from '../../components/UI/popups/popup';

import * as actions from '../../store/actions';
import authaxios from '../../auth/authaxios';

class AddMaterial extends Component {
    // Set initial State
    state = {
        loading: false,
        materialItems: {
            "name": {"valid": true, "value": this.props.creatingNewMaterial ? null : this.props.material.name},
            "dueDate": {"valid": true, "value": this.props.creatingNewMaterial ? null : this.props.material.due_date}
        },
        errorMessage: null,
        disableSubmit: true
    }

    // Function to update the materialItems object in state
    updateMaterialItems = (item, key, value) => {
        var temp = this.state.materialItems;
        temp[item][key] = value;
        this.setState({materialItems: temp});
    }

    // Function that checks to see if the submit button should be disabled
    updateDisableSubmit = () => {
        var disableSubmit = !this.state.materialItems.name.valid || this.state.materialItems.dueDate.value == null;

        this.setState({disableSubmit: disableSubmit});
    }

    // Handler for when the submit button is pressed if this is a create material popup
    // Sends POST request with the new material information
    createConfirmHandler = (event) => {
        // Check material detail validity
        if (!this.checkName(this.state.materialItems.name.value)) {
            this.updateMaterialItems("name", "valid", false);
            return;
        }

        // turn on the loading spinner
        this.setState({loading: true});

        // Send post request with course information
        authaxios.post(`/course/material.php?course_id=${this.props.course_id}`, 
        {
            name: this.state.materialItems.name.value,
            due_date: this.state.materialItems.dueDate.value
        })
        .then(response => {
            // Update course material prop
            this.props.updateMaterial(response.data, null, "ADD");

            this.addBannerHandler("success", "Course material successfully added!", true);

            // turn off the loading spinner
            this.setState({loading: false});
            
            // Call cancel handler to close the popup
            this.props.cancelHandler();
        })
        .catch((error) => {
            // Display the error message in a banner, and turn off the loader
            this.setState({errorMessage: error.response.data.Response, loading: false});
            this.addBannerHandler("error", "Failed to add course material", true);
        });
    }

    // Handler for when the submit button is pressed if this is an edit material popup
    // Sends a PATCH request with updated material information
    editConfirmHandler = (event) => {
        // Check material detail validity
        if (!this.checkName(this.state.materialItems.name.value)) {
            this.updateMaterialItems("name", "valid", false);
            return;
        }
        
        // turn on the loading spinner
        this.setState({loading: true});
        
        // Make a PATCH request with updated material information
        authaxios.patch(`/course/material.php?material_id=${this.props.material.material_id}`, 
        {
            name: this.state.materialItems.name.value,
            due_date: this.state.materialItems.dueDate.value,
        })
        .then(response => {
            // Update user contacts prop
            this.props.updateMaterial(response.data, this.props.selectedMaterialIndex, "UPDATE");

            this.addBannerHandler("success", "Course material successfully updated!", true);

            // turn off the loading spinner
            this.setState({loading: false});
            
            // Call cancel handler to close the popup
            this.props.cancelHandler();
        })
        .catch((error) => {
            // Display the error message in a banner, and turn off the loader
            this.setState({errorMessage: error.response.data.Response, loading: false});
            this.addBannerHandler("error", "Failed to update course material", true);
        });
    }

    // Function that updates the material name value and validity in state
    // Called on onBlur and onChange
    nameHandler = (event) => {
        let name = event.target.value;
        var passed = this.checkName(name);

        this.updateMaterialItems("name", "value", name);
        this.updateMaterialItems("name", "valid", passed);
        this.updateDisableSubmit();
    }

    // Function that updates the date value in state
    // Called on onChange
    dateHandler = (event) => {
        let dueDate = event.target.value;
        this.updateMaterialItems("dueDate", "value", dueDate);
        this.updateDisableSubmit();
    }

    // Checks validity of the material name
    checkName = (name) => {
        return name.length < 45 && name.length > 0;
    }

    addBannerHandler = (type, text, autoClose=false, link = "", linkText = "") => {
        const banner = {
            type: type,
            link: link,
            linkText: linkText,
            text: text,
            autoClose: autoClose
        }
        this.props.addBanner(banner);
    }

    render() {
        return(
            <Popup show={true} title={this.props.creatingNewMaterial ? "Create Course Material" : "Edit Course Material"} loading={this.state.loading} confirmText={this.props.creatingNewMaterial ? "Confirm" : "Update"}
                 disableConfirm={this.state.disableSubmit} confirmHandler={this.props.creatingNewMaterial ? this.createConfirmHandler : this.editConfirmHandler} cancel={this.props.cancelHandler} errorMessage={this.state.errorMessage}>

                <LabeledInput label='Course Material Name' name='name' invalidBool={!this.state.materialItems.name.valid} checkHandler={this.nameHandler}
                     placeholder='Assignment 1' type='text' errorMsg='Invalid name' defaultValue={this.state.materialItems.name.value} />

                <LabeledInput label='Due Date' name='dueDate' defaultValue={this.state.materialItems.dueDate.value} checkHandler={this.dateHandler} type='date' />
            </Popup>
        );
    };
}

// Tell redux connect that we will call the redux action login() so we want it as props
const mapDispatchToProps = dispatch => {
    return {
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};
export default connect(null, mapDispatchToProps)(AddMaterial);