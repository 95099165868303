import React from 'react';
import styles from './inputs.module.css';

/*
-- Checkbox Component --
The styled checkbox component. Has inline block display
Props:
- className and/or id: to be applied to checkbox (label and box)
- disabled: bool representing whether the checkbox is disabled
- checked: bool representing whether the checkbox is checked
- name: the checkbox name
- onChange: function to be called when the value changes
- label: the checkbox's label
*/

const Checkbox = (props) => {
    return (
        <div className={styles.checkboxContainer}>
            <input disabled={props.disabled} type="checkbox" className={`${styles.checkbox} ${props.className}`} id={props.id} checked={props.checked} name={props.name} onChange={props.onChange} />
            <label className={styles.checkboxLabel}>{props.label}</label>
        </div>
    );
}

export default Checkbox;