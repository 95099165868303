import React from 'react';
import styles from './buttons.module.css';

/*
-- Submit Button --
The styled submit input
Props:
- className and/or id: applied to button
- disabled: bool representing whether the button is disabled or not
- text: text to appear on the button
*/

const SubmitBtn =(props) => {
    return (
        <input type="submit" name="submit" value={props.text} className={`${styles.btn} ${props.disabled ? styles.disabledBtn : styles.enabledBtn} ${props.className}`}
             id={props.id} onClick={props.onClick} disabled={props.disabled} />
    );
}

export default SubmitBtn;