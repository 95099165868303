import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as actions from '../../store/actions';
import styles from './Header.module.css';
import CloseButton from '../UI/buttons/CloseBtn';

// Class Author: @Joe Hall
class Banner extends Component {

    state = {
        currentBanner: null
    }

    //Banner timeout set to null by default
    bannerTimeout = null;

    // AUTHOR: @Joe Hall
    componentDidUpdate(prevProps, prevState) {
        // Only happens if props changed but state didn't - prevents infite loop
        if(prevState === this.state) {
            // Set the current banner
            let tempBannerArray = [...this.props.banners];
            let banner = tempBannerArray.pop();
            this.setState({currentBanner: banner});

            // if the banner is supposed to auto-close then set the timeout to close it
            if(banner && banner.autoClose) this.bannerTimeout = setTimeout(this.closeBannerHandler, 4000);
        }
    }

    // Handler for closing the current banner, clears the bannerTimeout if it's set
    // AUTHOR: @Joe Hall
    closeBannerHandler = () => {
        if(this.bannerTimeout) clearTimeout(this.bannerTimeout);
        this.props.removeBanner(this.props.banners.length -1);
    }


    // AUTHOR: @Joe Hall
    render() {
        
        // Find out which styles apply
        let style = [styles.Banner];
        if(this.state.currentBanner) {
            style.push(styles.Open);
            switch(this.state.currentBanner.type) {
                case "info":
                    style.push(styles.BannerInfo);
                    break;
                case "error":
                    style.push(styles.BannerError);
                    break;
                case "success":
                    style.push(styles.BannerSuccess);
                    break;
                default:
                    break;
            }

        }

            return(
                <div className={style.join(" ")}>
                    {this.state.currentBanner ? <span className={styles.BannerClose}><CloseButton onClick={this.closeBannerHandler} /></span> : null }
                    <span>{this.state.currentBanner ? this.state.currentBanner.text : null}</span>
                    {this.state.currentBanner && this.state.currentBanner.link !== "" ? <span className={styles.BannerButton}><Link to={this.state.currentBanner.link}>{this.state.currentBanner.linkText}</Link></span> : null }
                </div>
            )
    }
}

// Tell connect Redux which states we want to have handed to us as props.
// For this we want access to the redux state banners
const mapStateToProps = state => {
    return {
        banners: state.banners
    };
};

// Tell connect Redux which dispatch events we want to call
// We want to call REMOVE_BANNER
const mapDispacthToProps = dispatch => {
    return {
        removeBanner: (index) => dispatch({type: actions.REMOVE_BANNER, index: index})
    };
};

export default connect(mapStateToProps, mapDispacthToProps)(Banner);