import React, { Component, Suspense, lazy } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import Recaptcha from 'react-recaptcha';
import Header from './components/header/Header';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import ResetPassword from './pages/resetpassword/ResetPassword';
import Auth from './auth/Auth';
import Account from './pages/account/Account';
import Dashboard from './pages/dashboard/Dashboard';
import Course from './pages/course/Course';
import VerifyEmail from './pages/verify_email';
import Invite from './pages/Invite';

import './App.css';

// Importing Material as lazy load, to improve page loading time
const MaterialShell = lazy(() => import('./pages/course/MaterialShell'));;


class App extends Component {

  render() {

    let routes = (
      <>
        <Route path="/user/verify-email" component={VerifyEmail} />
        <Route path="/invite" component={Invite} />
        <Route path="/login" component={Login} />
      </>
    );

    let loggedOutRoutes = null;
    let loggedInRoutes = null;

    // Routes that should be accessable to not logged in users only
    if(!this.props.loggedIn) {
      loggedOutRoutes = (
        <React.Fragment>
          <Route path="/signup" component={Signup} />
          <Route path = "/reset/password" component={ResetPassword} />
          <Route path="/" exact component={Login} />
        </React.Fragment>
      );
    }
    
    
    // Routes that should be accessable to logged in users only
    if(this.props.loggedIn) {
      loggedInRoutes = (
        <React.Fragment>
          <Route path="/account" component={Account} />
          <Route path="/" exact component={Dashboard} />
          <Route path="/course/:id" component={Course} />
          <Suspense fallback={<div>Loading...</div>}>
            <Route path="/c/:cid/material/:mid" component={MaterialShell} />
          </Suspense>
        </React.Fragment>
      )
    }
    
    return (
      <React.Fragment>
        <Auth />
        <Header />
        {routes}
        {loggedOutRoutes}
        {loggedInRoutes}
      </React.Fragment>
    );
  }
}


const mapStateToProps = state => {
  return {
      loggedIn: state.loggedIn
  }
}

export default withRouter(connect(mapStateToProps, null)(App));
