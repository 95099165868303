import React from 'react';

import styles from './Spinner.module.css';

// function based component for displaying a spinner. It expects pops.show (boolean) to display the spinner
// The spinner is CSS based and was taken from a Creative Commons CSS library online.
// AUTHOR: @Joe Hall
const Spinner = ({show}) => {
    if(show) {
        return  <div className={styles.Spinner} title="Loading..."><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    } else return null;
}

export default Spinner;