import React from 'react';

import styles from './Backdrop.module.css';

// Function based Component for showing a semi-translucent backdrop.
// Takes props.show (boolean) and props.cancel. Cancel is what function to call when the user
// clicks on the backdrop. show is a boolean to represent whether the backdrop should appear or not
// AUTHOR: @Joe Hall
const backdrop = (props) => (
    props.show ? <div className={styles.Backdrop} onClick={props.cancel}></div> : null
);

export default backdrop;