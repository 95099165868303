import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import Backdrop from '../UI/popups/Backdrop';
import { FaBars } from 'react-icons/fa';
//import { FaBell } from 'react-icons/fa';
import PNLogo from '../../assets/PNlogo-small.png';
import styles from './Header.module.css';
import * as actions from '../../store/actions';
import Banner from './Banner';

// Class for showing the top header
// class author: @joe hall
class Header extends Component {

    state = {
        mobileMenuDisplay: false
    };

    // This handler handles the user clicking logout, it calls hideMobileMenu, and then calls the redux action Logout()
    // AUTHOR: @Joe Hall
    logoutHandler = () => {
        this.hideMobileMenu();
        this.props.logout();
        axios.get("/user/logout.php")
        .catch(error => {
            let banner = {
                type: "error",
                text: "An error occured while trying to log you out.",
                link: "",
                linkText: "",
                autoClose: true
            };
            this.props.addBanner(banner);
        });

    }

    // This function hides the mobile menu. Should be called when a user clicks on a nav link to stop it from staying open
    // AUTHOR: @Joe Hall
    hideMobileMenu = () => {
        if (this.state.mobileMenuDisplay) {
            this.toggleMobileMenuHandler();
        }
    }

    //this function toggles the mobile menu on or off, depending on whether or not it is currently open. 
    // AUTHOR: @Joe Hall
    toggleMobileMenuHandler = () => {
        this.setState({
            mobileMenuDisplay: !this.state.mobileMenuDisplay
        });
    }

    // React Component class function. It contains the HTML for displaying the Header.
    // AUTHOR: @Joe Hall
    render() {
        return(
            <React.Fragment>
                <Backdrop show={this.state.mobileMenuDisplay} cancel={this.hideMobileMenu} />
                <div className={styles.Header}>
                    <div className={styles.topnav} id="myTopnav">
                        <Link to="/" className={styles.title} onClick={this.hideMobileMenu}>
                            <img src={PNLogo} alt="PathNOTES" className="PNLogo" />
                        </Link>
                        {!this.props.loggedIn ? <NavLink to="/login" title="Log into PathNOTES" onClick={this.hideMobileMenu}>Login</NavLink> : null }
                        {!this.props.loggedIn ? <NavLink to="/signup" title="Get started with PathNOTES" onClick={this.hideMobileMenu}>Get started</NavLink> : null }
                        {this.props.loggedIn ? <button className={styles.LogoutButton} title="Log out of PathNOTES" onClick={this.logoutHandler}>Logout</button> : null }
                        {this.props.loggedIn ? <NavLink to="/account/profile" title="Visit your account settings" onClick={this.hideMobileMenu}>My account</NavLink> : null }
                        {this.props.loggedIn ? <NavLink to="/" title="View your courses" onClick={this.hideMobileMenu}>Courses</NavLink> : null }
                        <button className={styles.icon} onClick={this.toggleMobileMenuHandler}>
                            <FaBars />
                        </button>
                    </div>
                    <Banner />
                    <div className={this.state.mobileMenuDisplay ? styles.MobileMenu + " " + styles.responsive : styles.MobileMenu }>
                        {!this.props.loggedIn ? <NavLink to="/login" title="Log into PathNOTES" onClick={this.hideMobileMenu}>Login</NavLink> : null }
                        {!this.props.loggedIn ? <NavLink to="/signup" title="Get started with PathNOTES" onClick={this.hideMobileMenu}>Get started</NavLink> : null }
                        {this.props.loggedIn ? <NavLink to="/" title="View your courses" onClick={this.hideMobileMenu}>Courses</NavLink> : null }
                        {this.props.loggedIn ? <NavLink to="/account/profile" title="Visit your account settings" onClick={this.hideMobileMenu}>My account</NavLink> : null }
                        {this.props.loggedIn ? <button className={styles.LogoutButton} title="Log out of PathNOTES" onClick={this.logoutHandler}>Logout</button> : null }
                    </div>
                </div>
            </React.Fragment>
    )};
}

// Tell connect Redux which states we want to have handed to us as props. For this we want to
// know the user and if the user is logged in.
const mapStateToProps = state => {
    return {
        user: state.user,
        loggedIn: state.loggedIn
    };
};

// Tell connect Redux which dispatch events we want to call
// For this class we want to be able to call the redux function USER_LOGOUT
const mapDispacthToProps = dispatch => {
    return {
        logout: () => dispatch({type: actions.USER_LOGOUT}),
        addBanner: (banner) => dispatch({type: actions.ADD_BANNER, banner: banner})
    };
};

//wrap our component in redux connect so we can access the required state variables, then export the Header class as the default
export default connect(mapStateToProps, mapDispacthToProps)(Header);