import React, { Component } from 'react';
import { connect } from 'react-redux';

import authaxios from '../../../auth/authaxios';

import styles from '../Course.module.css';
import inviteStyles from './invite_student.module.css';
import ExpandContainer from '../../../components/UI/misc/ExpandContainer';
import TextArea from '../../../components/UI/inputs/TextArea';
import { FaStar } from 'react-icons/fa';
import Fail from '../../../components/UI/messages/Fail';

//import authaxios from '../../../auth/authaxios';

class InviteInstructors extends Component {
    // Set initial State
    state = {
        courseId: null,
        emails: "",
        emailError: "",
        sentEmails: [],
        rejectedEmails: []
    }

    // When the component mounts, set the submit handler on ConfigPopup.js to this.onSubmitHandler
    // Also grab the courseId from props to set this.state.courseId
    // Also the disable submit
    componentDidMount() {
        this.props.handlerSetter(this.onSubmitHandler, "Invite");
        this.setState({courseId: this.props.courseId});
        this.props.disableSubmit(true);
    }

    // Handler for when the user submits the 'invite instructors' form
    onSubmitHandler = () => {
        // Check to make sure emails isn't empty, if so set emailError message
        if(this.state.emails === "") {
            this.setState({emailError: "You must enter at least one email"});
            return;
        }

        // Turn the loading spinner on
        this.props.loadingHandler(true);

        // Get the emails from state and trim any whitespace after them
        let emails = this.state.emails.trim();

        // Set the email array to empty
        let emailArray = [];

        // If emails don't contain any delimeters (ie space, comma, newline) then push the whole emails string to the emailArray
        if(!/[\s,\r\n]+/.test(emails)) emailArray.push(emails);

        // Associative array of regexes and their delimiters
        const regexes = {",": /,+/, " ": /\s+/, "\r": /\r+/, "\n": /\n+/};

        // If the emailArray still empty, then we know the emails string contains delimiters
        if(emailArray.length === 0) {
            // Loop through each delimeter and attempt to spit the emails string based on that delimeter.
            // If it enters a loop and the email array already contains more than 1 item then break out of loop
            // because another regex already successfully split it up
            for(let key in regexes) {
                if(emailArray.length > 1) break;
                if(regexes[key].test(emails)) emailArray = emails.split(key);
            }
        }

        // Send the array to the api. If success, store the sentemails and rejected emails in state,
        // and if there were rejected emails show an error message to the user.
        // If it fails show an error message with what the api sent back
        authaxios.post(`/course/invite.php?course_id=${this.props.course.course_id}&instructor=true`, {
            emails: emailArray
        })
        .then( response => {
            let errorMessage = response.data.rejected_emails.length > 0 ? "Not all emails could be sent" : "";
            this.setState({sentEmails: response.data.sent_emails, rejectedEmails: response.data.rejected_emails, emailError: errorMessage});
        })
        .catch( error => {
            this.setState({emailError: error.response.data.Response});
        })
        .finally(() => {
            // Turn off the loading spinner
            this.props.loadingHandler(false);
        });
    }

    // Handler for when email input changes.
    // Updates state.emails with the new values.
    // Also enables/disables submit depending on whether the email input is empty or not
    emailInputChangeHandler = (event) => {
      this.props.disableSubmit(event.target.value === "");
      this.setState({emails: event.target.value});
    }

    render() {
        return(
            <div>
                <p>You can seperate emails by commas, spaces, or by newlines (i.e. one email per line)</p>
                <div className={inviteStyles.emailResponse}>
                    {this.state.sentEmails.length > 0 ? 
                        <div className={inviteStyles.successEmails}>
                            <h4>Sent Emails</h4>
                            <ul>
                                {this.state.sentEmails.map((value, index) => {
                                    return(
                                        <li key={"sentEmail" + index}>{value}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    : null}
                    {this.state.rejectedEmails.length > 0 ? 
                        <div className={inviteStyles.failedEmails}>
                            <h4>Rejected Emails</h4>
                            <ul>
                                {this.state.rejectedEmails.map((value, index) => {
                                    return(
                                        <li key={"rejectedEmail" + index}>{value}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    : null}
                </div>

                <Fail message={this.state.emailError} show={this.state.emailError !== ""} />
                <TextArea name="emails" value={this.state.emails} placeholder="Enter emails here..." invalidBool={this.state.emailError !== ""} checkHandler={this.emailInputChangeHandler} errorMsg={this.state.emailError} col="40" row="10" />
                
                <h3 className={styles.label} >Other course instructors</h3>
                {this.props.course.instructors.length > 1 ? this.props.course.instructors.map((instructor, index) => {
                    if (instructor.username !== this.props.user.username) {
                        return (
                            <ExpandContainer key={index} label={instructor.fname + " " + instructor.lname}>
                                {instructor.contacts.emails.map((contact, index) => {
                                    return <div key={index} className={styles.contact}>{contact.preferred_contact === "1" ? <FaStar style={{color: "yellow"}} /> : null} <a href={`mailto:${contact.contact}`} title="Send them an email">{contact.contact}</a></div>
                                })}
                                {instructor.contacts.phone_numbers.map((contact, index) => {
                                    return <div key={index} className={styles.contact}>{contact.preferred_contact === "1" ? <FaStar style={{color: "yellow"}} /> : null} <a href={`tel:${contact.contact}`} title="Call them">{contact.contact}</a></div>
                                })}
                            </ExpandContainer>
                        )
                    } else {
                        return null;
                    }
                }) : <p className={styles.label}>You are currently the only instructor for {this.props.course.name}.</p>}
            </div>
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(InviteInstructors);