import React, { Component } from 'react';

import styles from './Course.module.css';
import Spinner from '../../components/UI/loading/Spinner/Spinner';
import ExpandContainer from '../../components/UI/misc/ExpandContainer';
import { FaStar } from 'react-icons/fa';

class CourseInfo extends Component {
    state = {
        loading: false
    }

    render() {
        if (this.props.loading) {
            return (
                <div>
                    <h1>Course Information</h1>
                    <Spinner show={this.props.loading} />
                </div>
            );
        }
        return (
            <div>
                <h1>Course Information</h1>
                <p>This course is currently {this.props.course.active === '1' ? <span className={styles.active}>Active</span> : <span className={styles.inactive}>Inactive</span> }</p>
                <div className={`${styles.mainContent} ${styles.leftAligned}`}>
                    <h3 className={styles.label}>Name</h3>
                    <p>{this.props.course.name}</p>
                    <h3 className={styles.label}>Code</h3>
                    <p>{this.props.course.code}</p>
                    <h3 className={styles.label}>Description</h3>
                    <p>{this.props.course.description}</p>
                    <h3 className={styles.label}>{this.props.course.instructors && this.props.course.instructors.length > 1 ? "Instructors" : "Instructor" }</h3>
                    {this.props.course.instructors ?
                    <>
                    {this.props.course.instructors.map((instructor, index) => {
                        return (
                            <ExpandContainer key={index} label={instructor.fname + " " + instructor.lname}>
                                {instructor.contacts.emails.map((contact, index) => {
                                    return <div key={index} className={styles.contact}>{contact.preferred_contact === "1" ? <FaStar style={{color: "yellow"}} /> : null} <a href={`mailto:${contact.contact}`} title="Send them an email">{contact.contact}</a></div>
                                })}
                                {instructor.contacts.phone_numbers.map((contact, index) => {
                                    return <div key={index} className={styles.contact}>{contact.preferred_contact === "1" ? <FaStar style={{color: "yellow"}} /> : null} <a href={`tel:${contact.contact}`} title="Call them">{contact.contact}</a></div>
                                })}
                            </ExpandContainer>
                        )
                    })}
                    </>
                    : null}
                </div>
            </div>
        );
    };
}

export default CourseInfo;