// AUTHOR: @Joe Hall
import axios from 'axios';
import { apiURL, IS_DEVELOPMENT } from '../Globals';
// Creates an instance of Axios
const authaxios = axios.create({
    baseURL: apiURL
});

// ****** REMOVING THIS SECTION BECAUSE WE WILL USE COOKIES INSTEAD
if (IS_DEVELOPMENT) {
    // Sets the Authorization header to a placeholder value for now
    authaxios.defaults.headers.common['Authorization'] = 'AUTH_KEY';

    // Intercept all outgoing requests, add the JWT token stored in cookies to the request
    authaxios.interceptors.request.use(request => {
        let token = localStorage.token;
        request.headers.common['Authorization'] = token;
        return request;
    }, errors => {
        // console.log(errors);
    });
}

export default authaxios;