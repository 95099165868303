import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaCog } from 'react-icons/fa';

import authaxios from '../../auth/authaxios';
import Button from '../../components/UI/buttons/Button';
//import Spinner from '../../components/UI/loading/Spinner/Spinner';
import FullPageLoad from '../../components/UI/loading/fullpageload/fullpageload';

import styles from './Course.module.css';
import ConfigPopup from './configure/ConfigPopup';
import Sidebar from '../../components/sidebar/Sidebar';
import CourseInfo from './CourseInfo';
import CourseMaterial from './CourseMaterial';
//import Material from './question/Material';


class Course extends Component {

    state = {
        loading: false,
        showConfigPopup: false,
        instructing: false,
        course: {
            active: false,
            code: "",
            colour: "",
            course_id: "",
            description: "",
            name: "",
            instructors: []
        },
        courseMaterial: []
    }

    // Called when the configure button is pressed
    // Shows the configuration popup
    configBtnHandler = () => {
        this.setState({showConfigPopup: true});
    }

    // Called to close the configure popup
    configCancelHandler = () => {
        this.setState({showConfigPopup: false});
    }

    // Function for updating the course object in state
    updateCourseHandler = (course) => {
        this.setState({course: course});
    }

    // Function for doing something with course material
    // Can UPDATE a course material in the course material array in state
    // Can ADD a course material to the course material array in state
    // Can DELETE a course material in the course material array in state
    updateMaterialHandler = (material, indexToUpdate, updateType) => {
        let newMaterialList = [...this.state.courseMaterial];
        switch(updateType) {
            case "UPDATE":
                newMaterialList[indexToUpdate] = material;
                break;
            case "ADD":
                newMaterialList = [...newMaterialList, material];
                break;
            case "DELETE":
                newMaterialList.splice(indexToUpdate, 1);
                break;
            default:
                newMaterialList = this.state.courseMaterial;
                break
        }

        this.setState({courseMaterial: newMaterialList});
    }

    // Makes a GET request to get the course's information and material
    // Gets the course id from the URL
    componentDidMount() {
        // Set the spinner
        this.setState({loading: true});

        //Get the course ID from the URL
        const { id } = this.props.match.params;

        //Get the course from the api
        authaxios.get(`/course.php?id=${id}`)
        .then(response => {
            this.setState({course: response.data});
            response.data.instructors.forEach(instructor => {
                if (instructor.username === this.props.user.username) this.setState({instructing: true});
            });
        })
        .catch(error => {
        });

        //Get the course's material from the api
        authaxios.get(`/course/material.php?course_id=${id}`)
        .then(response => {
            this.setState({courseMaterial: response.data});
        })
        .catch(error => {
            //console.log(error.response.data.Response);
        })
        .finally(() => {
            this.setState({loading: false});
        });
    }

    render(){
        return(
            <>
                <FullPageLoad show={this.state.loading} />
                {this.state.showConfigPopup && !this.state.loading ? <ConfigPopup cancelHandler={this.configCancelHandler} course={this.state.course} updateCourseHandler={this.updateCourseHandler} /> : null}
                {this.state.instructing ? 
                    <Button id={styles.configBtn} onClick={this.configBtnHandler}><FaCog />&nbsp;Configure</Button>
                : null}

                <Sidebar title={`${this.state.course.code}: ${this.state.course.name}`} links={[
                    {"path": `/course/${this.state.course.course_id}/information`, "title": "View course information", "label": "Course Information", "component": <CourseInfo course={this.state.course} loading={this.state.loading} updateCourseHandler={this.updateCourseHandler}/>},
                    {"path": `/course/${this.state.course.course_id}/content`, "title": "View course content", "label": "Course Content", "component": <CourseMaterial courseMaterial={this.state.courseMaterial} updateCourseMaterial={this.updateMaterialHandler} instructing={this.state.instructing} courseId={this.state.course.course_id} />, 
                        "childLinks": this.state.courseMaterial.length === 0 ? undefined : 
                            this.state.courseMaterial.map(material => {
                                return ({
                                    "path": `/c/${this.state.course.course_id}/material/${material.material_id}`,
                                    "title": "View this course material",
                                    "label": material.name,
                                    "component": null
                                });
                            })
                    }
                ]} />
            </>
        );
    };
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(Course);